import { useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  Grid,
  Alert
} from '@material-ui/core';
import backend from '../../api/backend';

const { http, setLocalJwt } = backend

const FormUsuarios = ({ usuario, regresar }) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [message, setMessage] = useState("")

  const registerUser = (values) => {
    if (usuario.id === "") {
      setStatus(true)
      setErrorMessage(false)
      http.post('api/register', values).then((resp) => {
        const r = resp.data;
        setStatus(false)
        if (!!r.access_token) {
          regresar(true)
        } else {
          setErrorMessage(true)
          setMessage(r.message)
        }
      }).catch((error) => {
        setStatus(false)
      });
    } else {
      setStatus(true)
      setErrorMessage(false)
      values.tipoUser = usuario.type_user_id;
      http.post(`api/update_user/${usuario.id}`, values).then((resp) => {
        const r = resp.data;
        setStatus(false)
        if (r.status === "success") {
          regresar(true)
        } else {
          setErrorMessage(true)
          setMessage(r.message)
        }
      }).catch((error) => {
        setStatus(false)
      });
    }
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="md">
          <Formik
            initialValues={{
              id: usuario.id,
              email: usuario.email,
              fullname: usuario.fullname,
              correo: usuario.correo,
              password: usuario.password,
              nit: usuario.nit,
              company_name: usuario.company_name,
              cellphone: usuario.cellphone,
              foto: usuario.foto,
              tipoUser: usuario.tipoUser, //1
              estado: usuario.estado, //act
            }}
            validationSchema={
              Yup.object().shape({
                correo: Yup.string().email('Debe ser un correo válido').max(255).required('Correo es requerido'),
                fullname: Yup.string().max(255).required('El nombre es requerido'),
                email: Yup.string().max(255).required('El usuario es requerido'),
                password: Yup.string().max(255).required('Password es requerido'),
              })
            }
            onSubmit={(values) => {
              registerUser(values)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Usuarios
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Ingrese los datos correctos
                  </Typography>
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={Boolean(touched.fullname && errors.fullname)}
                      fullWidth
                      helperText={touched.fullname && errors.fullname}
                      label="Nombre completo"
                      margin="normal"
                      name="fullname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.fullname}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.correo && errors.correo)}
                      fullWidth
                      helperText={touched.correo && errors.correo}
                      label="Correo electrónico"
                      margin="normal"
                      name="correo"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.correo}
                      variant="outlined"
                    />
                    <TextField
                      fullWidth
                      label="Nit"
                      margin="normal"
                      name="nit"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.nit}
                      variant="outlined"
                    />
                    <TextField
                      fullWidth
                      label="Nombre empresa"
                      margin="normal"
                      name="company_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.company_name}
                      variant="outlined"
                    />
                    <TextField
                      fullWidth
                      label="Celular"
                      margin="normal"
                      name="cellphone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values.cellphone}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Usuario"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Contraseña"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                    <Box sx={{ py: 2 }}>
                      <Button
                        color="primary"
                        disabled={status}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        { status ? 'Registrando ...' : 'Guardar'}
                      </Button>
                    </Box>
                    <Box sx={{ py: 2 }}>
                      <Button
                        color="secondary"
                        disabled={status}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={() => regresar(false)}
                      >
                        Regresar
                      </Button>
                    </Box>
                    { errorMessage ? <Alert severity="error">{message}</Alert> : ""}
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default FormUsuarios;
