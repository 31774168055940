import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import {
  Box, Button, Card, CardContent,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography, Grid
} from '@material-ui/core';
import ProductCard from 'src/components/product/ProductCard';
import * as XLSX from 'xlsx'
import backend from '../api/backend';
import tablas from '../__mocks__/tablas';
import TablaMercaderias from '../components/product/TablaMercaderias';
import TablaDespachos from '../components/product/TablaDespachos';
import TablaLiquidacion from '../components/product/TablaLiquidacion';

const { getUser } = backend

let user = getUser()

const Productos = () => {
  const { http } = backend
  const [showTable, setShowTable] = useState(false)
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('loading')
  const [message, setMessage] = useState('Subiendo ...')
  const [theData, setTheData] = useState({})
  const [currentUserId, setCurrentUserId] = useState(getUser())
  const [currentList, setCurrentList] = useState('')
  const [numero, setNumero] = useState(0)
  const [totales, setTotales] = useState([])
  const handleClose = () => {
    setOpen(false);
  };
  const showTablas = (propsx) => {
    setCurrentList(propsx.id)
    setShowTable(true)
  }
  const regresarLista = () => {
    setShowTable(false)
  }
  const actualizarDescripcion = () => {
    setNumero(numero + 1)
  }
  const getTotales = () => {
    console.log(user)
    http.get(`api/totales_cliente/${user.id}`).then((resp) => {
      let r = resp.data
      let opciones = JSON.parse(JSON.stringify(tablas))
      opciones.forEach((e) => {
        if (e.id === 'mercaderia') {
          e.totalDownloads = r.descripcion[0].total
        }
        if (e.id === 'despachos') {
          e.totalDownloads = r.mercaderias[0].total
        }
        if (e.id === 'liquidacion') {
          e.totalDownloads = r.liquidacion[0].total
        }
      })
      setTotales(opciones)
    }).catch((error) => {
      console.log(error)
    })
  }
  let numerox = 1
  useEffect(() => {
    getTotales()
  }, [numerox]);
  return (
    <>
      <Helmet><title>Mercadería</title></Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
        <Container maxWidth={false}>
          <Box sx={{ pt: 1 }}>
            {!showTable
              ? (
                <Card>
                  <CardContent>
                    <Box>
                      <Grid container spacing={3}>
                        {totales.map((product) => (
                          <Grid item key={product.id} lg={4} md={6} xs={12}>
                            <ProductCard product={product} style={{ cursor: 'pointer' }} onClick={() => showTablas(product)} />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              )
              : (
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} xs={12}>
                    {
                      // eslint-disable-next-line no-nested-ternary
                      currentList === 'mercaderia'
                        ? <TablaMercaderias lista={currentList} clienteId={currentUserId.id} numero={numero} actualizar={actualizarDescripcion} soloLectura={false} />
                        : currentList === 'despachos'
                          ? <TablaDespachos lista={currentList} clienteId={currentUserId.id} numero={numero} actualizar={actualizarDescripcion} soloLectura={false} />
                          : <TablaLiquidacion lista={currentList} clienteId={currentUserId.id} numero={numero} actualizar={actualizarDescripcion} soloLectura={false} />
                    }
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <Card>
                      <CardContent>
                        <Grid container direction="row" justifyContent="flex-end" alignItems="right">
                          <Grid item>
                            <Button color="secondary" variant="contained" size="small" onClick={() => regresarLista()}>
                              Regresar lista de tablas
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
          </Box>
        </Container>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ textAlign: 'center' }}>
            <img style={{ width: '200px' }} src={`/${status}.gif`} alt="Loading" />
            <Typography variant="h4">{ message }</Typography>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  )
}

export default Productos;
