import { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup
} from '@material-ui/core';
import excel from '../../utils/excel';
import backend from '../../api/backend';

const DownloadExcel = (props) => {
  const { http } = backend
  const { onClose, value: valueProp, open, datos, laData, elFiltro, elValor, clienteId, servicio, ...other } = props;
  const [losDatos, setLosDatos] = useState(datos)
  const [todo, setTodo] = useState(false)
  const handleChange = (event) => {
    const newList = losDatos.map((item) => {
      if (item.label === event.target.name) {
        return { ...item, check: event.target.checked }
      }
      return item
    })
    setLosDatos(newList);
  };

  const handleChangeChecked = (event) => {
    setTodo(event.target.checked)
    const newList = losDatos.map((item) => ({ ...item, check: event.target.checked }))
    setLosDatos(newList);
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    let url = ""
    if (servicio === 'mercaderia') {
      url = "api/get_descripcion_minima_all"
    }
    if (servicio === 'liquidacion') {
      url = "api/get_liquidacion_all"
    }
    http.post(`${url}/${clienteId}`, {
      filtro: elFiltro,
      valor: elValor
    }).then((resp) => {
      const r = resp.data;
      const filteredColumns = losDatos.map((a) => (a.check ? 'false' : a.value))
      filteredColumns.push('id', 'registro_id', 'created_at', 'updated_at', 'cliente_id', 'user_id', 'observaciones')
      console.log(filteredColumns)
      r.forEach((x) => {
        filteredColumns.forEach((y) => {
          if (y !== 'false') {
            // eslint-disable-next-line no-param-reassign
            delete x[y]
          }
        })
      })
      excel.objectToExcel(r, "Mercaderia", "mercaderia")
      onClose();
    }).catch((error) => {
      console.log(error)
    })
    // const filteredColumns = losDatos.map((a) => (a.check ? 'false' : a.value))
    // laData.forEach((x) => {
    //   filteredColumns.forEach((y) => {
    //     if (y !== 'false') {
    //       // eslint-disable-next-line no-param-reassign
    //       delete x[y]
    //     }
    //   })
    // })
    // excel.objectToExcel(laData, "Algo", "tramites")
    // onClose();
  };

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">Seleccione columnas a exportar</DialogTitle>
      <DialogContent dividers>
        <FormGroup>
          <Checkbox checked={todo} onChange={handleChangeChecked} name="Seleccionar Todo" />
          {losDatos.map((m, index) => (
            <FormControlLabel
              control={<Checkbox checked={m.check} onChange={handleChange} name={m.label} />}
              label={m.label}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Salir
        </Button>
        <Button onClick={handleOk} color="primary">
          Generar Excel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default DownloadExcel
