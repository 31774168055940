import Swal from 'sweetalert2'

const al = {
  openLoading: (titulo) => {
    Swal.fire({
      title: titulo,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })
  },
  closeLoading: () => {
    Swal.close()
  },
  openSuccess: (mensaje, tiempo = 2000) => {
    Swal.fire({
      icon: 'success',
      title: mensaje,
      showConfirmButton: false,
      timer: tiempo
    })
  },
  openError: (mensaje, tiempo = 2000) => {
    Swal.fire({
      icon: 'error',
      title: mensaje,
      showConfirmButton: false,
      timer: tiempo
    })
  },
}
export default al
