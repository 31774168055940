import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import {
  Box, Button, Card, CardContent,
  Container, Dialog, DialogContent,
  Grid, InputAdornment,
  Pagination, SvgIcon, TextField,
  Typography,
  DialogTitle
} from '@material-ui/core';
import ProductCard from 'src/components/product//ProductCard';
import tablas from 'src/__mocks__/tablas';
import { useRef, useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import backend from '../api/backend';
import TablaMercaderias from '../components/product/TablaMercaderias';
import TablaDespachos from '../components/product/TablaDespachos';
import TablaLiquidacion from '../components/product/TablaLiquidacion';
import al from '../mixins/alertas'

const ClientPanel = () => {
  const { http, getUser } = backend
  const [current, setCurrent] = useState(JSON.parse(localStorage.getItem('current')))
  const [user, setUser] = useState(getUser())
  const [showTabla, setShowTabla] = useState(false)
  const [currentList, setCurrentList] = useState('')
  // Para el loading
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('loading')
  const [message, setMessage] = useState('Subiendo ...')
  //update aux
  const [numero, setNumero] = useState(0)
  const [totales, setTotales] = useState([])
  //mounted
  const navigate = useNavigate();
  const getTotales = () => {
    al.openLoading('Buscando resumenes ...')
    http.get(`api/totales_cliente/${current.id}`).then((resp) => {
      let r = resp.data
      let opciones = JSON.parse(JSON.stringify(tablas))
      opciones.forEach((e) => {
        if (e.id === 'mercaderia') {
          e.totalDownloads = r.descripcion[0].total
        }
        if (e.id === 'despachos') {
          e.totalDownloads = r.mercaderias[0].total
        }
        if (e.id === 'liquidacion') {
          e.totalDownloads = r.liquidacion[0].total
        }
      })
      setTotales(opciones)
    }).catch((error) => {
      al.openError(`Error al obtener los totales ${error}`)
      console.log(error)
    }).finally(() => {
      al.closeLoading()
    })
  }
  let numerox = 1
  useEffect(() => {
    getTotales()
  }, [numerox]);
  const showTablas = (propsx) => {
    setCurrentList(propsx.id)
    setShowTabla(true)
  }
  function regresar() {
    localStorage.setItem('current', "")
    navigate('/app/clientes')
  }
  function regresarLista() {
    setCurrentList('')
    setShowTabla(false)
  }
  function FileUpload({ getData, showDialog, guardarExcel }) {
    const [file, setFile] = useState("")
    const hiddenFileInput = useRef(null)

    const handleClick = (event) => {
      hiddenFileInput.current.click()
    }

    function handleUpload(event) {
      setFile(event.target.files[0])
      showDialog()
      let reader = new FileReader()
      reader.readAsArrayBuffer(event.target.files[0])
      reader.onloadend = (e) => {
        let data = new Uint8Array(e.target.result)
        let workbook = XLSX.read(data, { type: 'array', cellDates: true, dateNF: 'yyyy/mm/dd;@' })
        workbook.SheetNames.forEach((sheetName) => {
          let rowExcel = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { raw: false })
          console.log(rowExcel)
          guardarExcel(rowExcel)
        })
      }
    }
    return (
      <div id="upload-box">
        <Button color="primary" variant="contained" onClick={handleClick}>Importar Excel</Button>
        <input ref={hiddenFileInput} onChange={handleUpload} style={{ display: "none" }} type="file" />
      </div>
    )
  }
  const handleClose = (event = null, reason = '') => {
    console.log(reason)
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpen(false)
    }
  };
  const saveExcel = (excel) => {
    if (currentList === 'mercaderia') {
      http.post(`api/subir_descripcion/${user.id}/${current.id}`, excel).then((resp) => {
        const r = resp.data;
        if (r.status === 'error') {
          alert(`error al subir los datos mensaje de error: ${r.message}`)
        }
        setNumero(numero + 1)
      }).catch((error) => {
        Swal.fire(`Error al guardar: ${error}`, '', 'info')
      }).finally(() => {
        handleClose()
      });
    } else if (currentList === 'despachos') {
      console.log(excel)
      http.post(`api/upload_data/${user.id}/${current.id}`, excel).then((resp) => {
        const r = resp.data;
        if (r.status === 'error') {
          alert(`error al subir los datos mensaje de error: ${r.message}`)
        }
        setNumero(numero + 1)
      }).catch((error) => {
      }).finally(() => {
        handleClose()
      });
    } else if (currentList === 'liquidacion') {
      console.log(excel)
      http.post(`api/subir_liquidacion/${user.id}/${current.id}`, excel).then((resp) => {
        const r = resp.data;
        if (r.status === 'error') {
          alert(`error al subir los datos mensaje de error: ${r.message}`)
        }
        setNumero(numero + 1)
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        handleClose()
      });
    }
  }
  const showDialogx = () => {
    setOpen(true);
    setStatus('loading')
    setMessage('Subiendo ...')
  }
  const actualizarDescripcion = () => {
    setNumero(numero + 1)
  }
  const Toolbar = ({ guardarExcel, showDialog, obtenerTotales }) => {
    if (currentList === "") {
      return (
        <Card>
          <CardContent>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h3">
                  { `Cliente: ${current.fullname}` }
                </Typography>
              </Grid>
              <Grid item>
                <Button color="primary" variant="contained" size="small" onClick={() => obtenerTotales()}>
                  Actualizar registros
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )
    }
    return (
      <Card>
        <CardContent>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h3">
                { `Cliente: ${current.fullname}` }
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h3">
                {/* eslint-disable-next-line no-nested-ternary */}
                { `Tabla: ${currentList === 'mercaderia' ? 'Descripción de mercaderias' : currentList === 'liquidacion' ? 'Liquidación' : 'Despachos Realizados'}` }
              </Typography>
            </Grid>
            <Grid item>
              <FileUpload guardarExcel={(val) => guardarExcel(val)} showDialog={() => showDialog()} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  return (
    <>
      <Helmet>
        <title>Panel Cliente</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
        <Container maxWidth={false}>
          <Box sx={{ mt: 0 }}>
            <Toolbar guardarExcel={(val) => saveExcel(val)} showDialog={() => showDialogx()} obtenerTotales={() => getTotales()} />
          </Box>
          <Box sx={{ pt: 3 }}>
            {!showTabla
              ? (
                <Grid container spacing={3}>
                  {totales.map((product) => (
                    <Grid item key={product.id} lg={4} md={6} xs={12}>
                      <ProductCard product={product} style={{ cursor: 'pointer' }} onClick={() => showTablas(product)} />
                    </Grid>
                  ))}
                  <Grid item lg={12} md={12} xs={12}>
                    <Card>
                      <CardContent>
                        <Grid container direction="row" justifyContent="flex-end" alignItems="right">
                          <Grid item>
                            <Button color="secondary" variant="contained" size="small" onClick={() => regresar()}>
                              Regresar lista de clientes
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )
              : (
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} xs={12}>
                    {
                      // eslint-disable-next-line no-nested-ternary
                      currentList === 'mercaderia'
                        ? <TablaMercaderias lista={currentList} clienteId={current.id} numero={numero} actualizar={actualizarDescripcion} soloLectura />
                        : currentList === 'despachos'
                          ? <TablaDespachos lista={currentList} clienteId={current.id} numero={numero} actualizar={actualizarDescripcion} soloLectura />
                          : <TablaLiquidacion lista={currentList} clienteId={current.id} numero={numero} actualizar={actualizarDescripcion} soloLectura />
                    }
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <Card>
                      <CardContent>
                        <Grid container direction="row" justifyContent="flex-end" alignItems="right">
                          <Grid item>
                            <Button color="secondary" variant="contained" size="small" onClick={() => regresarLista()}>
                              Regresar lista de tablas
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
          </Box>
        </Container>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ textAlign: 'center' }}>
            <img style={{ width: '200px' }} src={`/${status}.gif`} alt="Loading" />
            <Typography variant="h4">{ message }</Typography>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  )
}
export default ClientPanel;
