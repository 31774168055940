/* eslint-disable no-lonely-if */
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Select,
  MenuItem,
  Typography,
  Grid,
  Alert
} from '@material-ui/core';
import backend from '../../api/backend';

const { http, setLocalJwt } = backend

const CrudClientes = ({ esCrear, tipo, data }) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [message, setMessage] = useState("")

  const registerUser = (values) => {
    setStatus(true)
    setErrorMessage(false)
    let route = tipo === 'crear' ? `api/register` : `api/update_user/${data.id}`
    http.post(route, values).then((resp) => {
      const r = resp.data;
      if (tipo === 'crear') {
        if (!!r.access_token) {
          setLocalJwt(r.access_token)
          esCrear('list')
        } else {
          setErrorMessage(true)
          setMessage(r.message)
        }
      } else {
        if (r.status === 'success') {
          esCrear('list')
        } else {
          setErrorMessage(true)
          setMessage(r.message)
        }
      }
    }).catch((error) => {
      console.log(error)
    }).finally(() => {
      setStatus(false)
    });
  }
  return (
    <>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          pt: 5,
          pb: 5
        }}
      >
        <Container maxWidth="md">
          <Formik
            initialValues={{
              email: data.email,
              fullname: data.fullname,
              correo: data.correo,
              password: '',
              nit: data.nit,
              company_name: data.company_name,
              cellphone: data.cellphone,
              foto: data.foto,
              tipoUser: 2,
              estado: data.estado,
            }}
            validationSchema={
              Yup.object().shape({
                correo: Yup.string().email('Debe ser un correo válido').max(255).required('Correo es requerido'),
                fullname: Yup.string().max(255).required('El nombre es requerido'),
                email: Yup.string().max(255).required('El usuario es requerido'),
                password: Yup.string().max(255).required('Password es requerido'),
              })
            }
            onSubmit={(values) => {
              registerUser(values)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    {tipo === 'crear' ? 'Crear Cliente' : 'Modificar Cliente'}
                  </Typography>
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={Boolean(touched.fullname && errors.fullname)}
                      fullWidth
                      helperText={touched.fullname && errors.fullname}
                      label="Nombre completo"
                      margin="normal"
                      name="fullname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.fullname}
                      variant="outlined"
                      size="small"
                    />
                    <TextField
                      error={Boolean(touched.correo && errors.correo)}
                      fullWidth
                      helperText={touched.correo && errors.correo}
                      label="Correo electrónico"
                      margin="normal"
                      name="correo"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.correo}
                      variant="outlined"
                      size="small"
                    />
                    <TextField
                      fullWidth
                      label="Nit"
                      margin="normal"
                      name="nit"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.nit}
                      variant="outlined"
                      size="small"
                    />
                    <TextField
                      fullWidth
                      label="Celular"
                      margin="normal"
                      name="cellphone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values.cellphone}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Usuario"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      variant="outlined"
                      size="small"
                    />
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Contraseña"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                      size="small"
                    />
                    <TextField
                      fullWidth
                      label="Nombre empresa"
                      margin="normal"
                      name="company_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.company_name}
                      variant="outlined"
                      size="small"
                    />
                    <TextField
                      fullWidth
                      label="Estado"
                      margin="normal"
                      name="estado"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      select
                      value={values.estado}
                      variant="outlined"
                      size="small"
                    >
                      <MenuItem value="ACT">ACTIVO</MenuItem>
                      <MenuItem value="INA">INACTIVO</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                      <Box sx={{ py: 2 }}>
                        {
                          tipo === 'crear' ? (
                            <Button
                              color="primary"
                              disabled={status}
                              fullWidth
                              size="small"
                              type="submit"
                              variant="contained"
                            >
                              { status ? 'Registrando ...' : 'Guardar Cliente'}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              disabled={status}
                              fullWidth
                              size="small"
                              type="submit"
                              variant="contained"
                            >
                              { status ? 'Modificando ...' : 'Modificar Cliente'}
                            </Button>
                          )
                        }
                      </Box>
                      <Grid item>
                        <Button color="secondary" variant="contained" size="small" onClick={() => esCrear('list')}>
                          Salir
                        </Button>
                      </Grid>
                    </Grid>
                    { errorMessage ? <Alert severity="error">{message}</Alert> : ""}
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default CrudClientes;
