import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/pages/Account';
import CustomerList from 'src/pages/CustomerList';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import ProductList from 'src/pages/ProductList';
import Register from 'src/pages/Register';
import Settings from 'src/pages/Settings';
import Clientes from './pages/Clientes';
import Archivos from './pages/Archivos';
import Usuarios from './pages/Usuarios';
import Productos from './pages/Productos';
import ClientPanel from './pages/ClientPanel';
import Cuenta from './pages/Cuenta';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      // { path: 'account', element: <Account /> },
      { path: 'account', element: <Cuenta /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'clientes', element: <Clientes /> },
      { path: 'archivos', element: <Archivos /> },
      { path: 'usuarios', element: <Usuarios /> },
      { path: 'productos', element: <Productos /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'products', element: <ProductList /> },
      { path: 'settings', element: <Settings /> },
      { path: 'clientpanel', element: <ClientPanel /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/register',
    element: <Register />
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      // { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
