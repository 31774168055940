import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import Swal from 'sweetalert2'
import { useEffect, useState } from 'react';
import al from '../../mixins/alertas';
import backend from '../../api/backend';

const AccountProfile = ({ user }) => {
  const { http } = backend
  const [laImagen, setLaImagen] = useState()
  const getImagePerfil = () => {
    if (!!user.id) {
      http.get(`api/show_file_perfil/${user.id}`).then((resp) => {
        const r = resp.data;
        let cadena = `data:image/png;base64, ${r.file}`
        setLaImagen(cadena)
      }).catch((error) => {
        console.log(error)
      })
    }
  }
  const uploadFile = async () => {
    const { value: file } = await Swal.fire({
      title: 'Seleccione la imagen',
      text: 'se recomienda fotos con una resolución máxima de 300 x 300 pixeles',
      input: 'file',
      confirmButtonText: 'Subir Archivo',
      inputAttributes: {
        accept: 'image/*',
        'aria-label': 'Upload your profile picture'
      }
    })
    if (file) {
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      let formData = new FormData()
      formData.append('file', file)
      al.openLoading("Subiendo")
      http.post(`api/upload_perfil/${user.id}`, formData, config).then((resp) => {
        const r = resp.data;
        al.closeLoading()
        al.openSuccess()
        getImagePerfil()
      }).catch((error) => {
        al.closeLoading()
        al.openError()
      })
    }
  }
  let numero = 1
  useEffect(() => {
    getImagePerfil()
  }, [user.foto])
  return (
    <Card>
      <CardContent>
        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <Avatar src={laImagen} sx={{ height: 100, width: 100 }} />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {user.fullname}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            {`${user.correo}`}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button color="primary" fullWidth variant="text" onClick={() => uploadFile()}>
          Subir Foto
        </Button>
      </CardActions>
    </Card>
  )
}
export default AccountProfile;
