import { useState, useRef, useEffect } from 'react';
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Autocomplete
} from '@material-ui/core';
import al from '../../mixins/alertas'
import backend from '../../api/backend';

const FileUpload = ({ elOpen, setTheFile }) => {
  const [fileName, setFileName] = useState("")
  const hiddenFileInput = useRef(null)

  useEffect(() => {
    if (elOpen) {
      setFileName('')
    }
  }, [elOpen])

  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  function handleUpload(event) {
    setTheFile(event.target.files[0])
    setFileName(event.target.files[0].name)
  }

  const showName = (filename) => {
    let col = filename === '' ? 'rojo' : 'verde'
    let name = filename === '' ? 'Sin Archivos' : filename
    return (
      <div className={`${col}`} style={{ padding: '6px', borderRadius: '3%', textAlign: 'center' }}>
        {name}
      </div>
    )
  }
  return (
    <div id="upload-box">
      <Button style={{ width: '100%' }} color="primary" variant="contained" onClick={handleClick}>Seleccionar Archivo</Button>
      <input ref={hiddenFileInput} onChange={handleUpload} style={{ display: "none" }} type="file" />
      <br />
      <br />
      {showName(fileName)}
    </div>
  )
}

const DialogArchivos = (props) => {
  const { onClose, open, actualizar } = props;
  const [nombreArchivo, setNombreArchivo] = useState("")
  const [archivo, setArchivo] = useState(null)
  const [clientes, setClientes] = useState([])
  const [textoCliente, setTextoCliente] = useState("")
  const { http } = backend

  const handleCancel = () => {
    onClose();
  };

  const setElFile = (fil) => {
    setArchivo(fil)
  }

  useEffect(() => {
    if (open) {
      setNombreArchivo("")
      setArchivo(null)
    }
  }, [open])

  const handleOk = () => {
    const config = { headers: { 'content-type': 'multipart/form-data' } }
    let formData = new FormData()
    formData.append('file', archivo)
    formData.append('nombre', nombreArchivo)
    formData.append('cliente', textoCliente.value)
    console.log(textoCliente.value)
    if (!nombreArchivo) {
      al.openError('Debe ingresar un nombre de archivo')
    } else if (!archivo) {
      al.openError('Debe subir un archivo')
    } else {
      handleCancel()
      al.openLoading("Subiendo")
      http.post(`api/upload_archivos`, formData, config).then((resp) => {
        const r = resp.data;
        al.closeLoading()
        al.openSuccess()
        actualizar()
      }).catch((error) => {
        al.closeLoading()
        al.openError(error)
      })
    }
  }

  const getClientes = () => {
    http.get(`api/get_users/2`).then((resp) => {
      const r = []
      resp.data.forEach((x) => {
        r.push({
          value: x.id,
          label: x.fullname,
        })
      })
      setClientes(r)
      console.log(r)
    }).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    getClientes()
  }, [0])

  return (
    <Dialog disableEscapeKeyDown open={open}>
      <DialogTitle style={{ backgroundColor: '#197ACE', color: '#ffffff' }} id="confirmation-dialog-title">Agregar Archivo</DialogTitle>
      <DialogContent dividers style={{ width: '500px' }}>
        <FormGroup>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre del archivo"
                margin="normal"
                name="fullname"
                value={nombreArchivo}
                onChange={(event) => setNombreArchivo(event.target.value)}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={clientes}
                value={textoCliente}
                onChange={(event, newValue) => {
                  setTextoCliente(newValue);
                }}
                id="idClient"
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Grid>
            <Grid item xs={12}>
              <FileUpload elOpen={open} setTheFile={(fil) => setElFile(fil)} />
            </Grid>
          </Grid>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Salir
        </Button>
        <Button onClick={handleOk} color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default DialogArchivos
