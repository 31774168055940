const Logo = (props) => (
  <img
    alt="Logo"
    width="100"
    // src="/static/logo.svg"
    src="/static/logo1.jpg"
    {...props}
  />
);

export default Logo;
