import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import AccountProfile from 'src/components/account/AccountProfile';
import AccountProfileDetails from 'src/components/account/AccountProfileDetails';
import { useEffect, useState } from 'react';
import backend from '../api/backend';

const Cuenta = () => {
  const { http, getUser } = backend
  const [currentUser, setCurrentUser] = useState({})
  const getUsuario = () => {
    let { id } = getUser()
    http.get(`api/get_user/${id}`).then((resp) => {
      const r = resp.data;
      r.avatar = '/static/images/avatars/default.png'
      setCurrentUser(r)
    }).catch((error) => {
      console.log(error)
    }).finally(() => {
    });
  }
  let numero = 0
  useEffect(() => {
    getUsuario()
  }, [numero])
  return (
    <>
      <Helmet>
        <title>Vaslec | Cuenta usuario</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <AccountProfile user={currentUser} />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              { Object.keys(currentUser).length > 0
                ? (
                  <AccountProfileDetails data={currentUser} />
                ) : ("Cargando ..")}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default Cuenta;
