import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import ClientesToolbar from 'src/components/customer/ClientesToolbar';
import backend from '../api/backend';
import CrudClientes from '../components/customer/CrudClientes';
import ClientTable from '../components/customer/ClientTable'
import al from '../mixins/alertas'

const { http } = backend

const Clientes = () => {
  const [view, setView] = useState("list")
  const [filter, setFilter] = useState("")
  const [clientes, setClientes] = useState([])
  const [cliente, setCliente] = useState([])
  const navigate = useNavigate();
  const getClientes = () => {
    al.openLoading("Buscando clientes")
    http.get(`api/get_users/${2}`).then((resp) => {
      const r = resp.data;
      if (filter !== "" && filter.length > 3) {
        let filtered = r.filter((x) => x.fullname.toLowerCase().includes(filter))
        setClientes(filtered)
      } else {
        setClientes(r)
      }
    }).catch((error) => {
      console.log(error)
    }).finally(() => {
      al.closeLoading()
    });
  }
  const esCrear = (valor) => {
    setCliente({
      cellphone: "",
      company_name: "",
      correo: "",
      created_at: "",
      email: "",
      foto: "",
      fullname: "",
      id: 0,
      nit: "",
      type_user_id: 0,
      updated_at: "",
      estado: 'ACT'
    })
    setView(valor)
    if (valor === 'list') getClientes()
  }
  const editar = (val) => {
    setCliente(val)
    setView('editar')
  }
  const filterTable = (val) => {
    let word = val.target.value
    setFilter(word)
    getClientes()
  }
  const showMercaderia = (val) => {
    localStorage.setItem('current', JSON.stringify(val))
    navigate('/app/clientpanel')
  }
  useEffect(() => {
    getClientes()
  }, [setClientes]);
  return (
    <>
      <Helmet><title>Clientes</title></Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
        <Container maxWidth={false}>
          {view === "list" ? <ClientesToolbar esCrear={(valor) => esCrear(valor)} filterTable={(text) => filterTable(text)} /> : ''}
          <Box sx={{ pt: 3 }}>
            { view === "list" ? <ClientTable clientes={clientes} editar={(val) => editar(val)} showMercaderia={(val) => showMercaderia(val)} /> : <CrudClientes tipo={view} data={cliente} esCrear={(valor) => esCrear(valor)} />}
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Clientes
