import { makeStyles } from '@material-ui/core/styles';
import { Cloud, FileCopy } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { useState, forwardRef } from 'react'
import {
  Fade,
  Button,
  IconButton,
  Toolbar,
  Dialog,
  AppBar,
  Typography,
  DialogContent,
  DialogActions,
  Container,
  Box,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper, Autocomplete, TextField
} from '@material-ui/core';
import backend from '../../api/backend';

const ModalFiles = ({ ide }) => {
  const [open, setOpen] = useState(false);
  const [filesList, setFileList] = useState([]);
  const [currentFile, setCurrentFile] = useState("")
  const [tipoArchivo, setTipoArchivo] = useState("")
  const [currentName, setCurrentName] = useState("")
  const { http, baseURL } = backend
  const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative'
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));
  const classes = useStyles();
  const getFiles = () => {
    http.get(`api/get_files/${ide}`).then((resp) => {
      const r = resp.data;
      setFileList(r)
    }).catch((error) => {
      console.log(error)
    }).finally(() => {
    });
  }
  function openDialog() {
    getFiles()
    setOpen(true)
  }
  const cerrarModal = () => {
    setOpen(false);
  };
  const showFile = ({ id, filename }) => {
    let cadena = "";
    let tipoArchivox = "";
    if (filename.includes('.png') || filename.includes('.jpg')) {
      // cadena = `data:image/png;base64, `
      tipoArchivox = "image"
    } else if (filename.includes('pdf')) {
      tipoArchivox = "pdf"
    }
    setTipoArchivo(tipoArchivox)
    setCurrentName(filename)
    http.get(`api/show_file/${id}`).then((resp) => {
      const r = resp.data;
      let base = `${cadena} ${r.file}`
      setCurrentFile(base)
    }).catch((error) => {
      console.log(error)
    }).finally(() => {
    });
  };
  const ShowRenderFile = ({ typeFile }) => {
    if (typeFile === 'pdf') {
      let cadenapdf = `data:application/pdf;base64, ${currentFile}`
      return (
        <Card>
          <Paper variant="outlined">
            <iframe width="900" height="700" title="el iframe" src={cadenapdf} />
          </Paper>
        </Card>
      )
    }
    if (typeFile === 'image') {
      let cadena = `data:image/png;base64, ${currentFile}`
      return (
        <Card>
          <Paper variant="outlined">
            <img width="900" src={cadena} alt="a" />
          </Paper>
        </Card>
      )
    }
    return ("")
  }
  const descargar = () => {
    let archivo = ""
    if (tipoArchivo === 'pdf') {
      archivo = `data:application/pdf;base64, ${currentFile}`
    } else if (tipoArchivo === 'image') {
      archivo = `data:image/png;base64, ${currentFile}`
    }
    let a = document.createElement('a')
    a.href = archivo
    a.download = currentName
    a.click()
    a.remove()
    // a.href = `${baseURL}/api/download_files/${currentFileId}`
    // a.target = '_blank'
    // a.click()
    // a.remove()
  }
  return (
    <div>
      <IconButton color="primary" aria-label="cloud" size="small" onClick={() => openDialog()}>
        <Cloud fontSize="inherit" />
      </IconButton>
      <Dialog fullScreen disableEscapeKeyDown maxWidth="xs" aria-labelledby="confirmation-dialog-title" open={open}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Lista de Archivos
            </Typography>
            <IconButton edge="start" color="inherit" onClick={cerrarModal} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {/*<DialogTitle id="confirmation-dialog-title">Seleccione columnas a exportar</DialogTitle>*/}
        <DialogContent dividers>
          <Container maxWidth={false}>
            <Box sx={{ pt: 7 }}>
              <Grid container>
                <Grid item lg={3} md={3} xs={12}>
                  <Card>
                    <CardContent>
                      <List component="nav" aria-label="main mailbox folders">
                        {
                          filesList.map((m, index) => (
                            <ListItem button onClick={() => showFile(m)}>
                              <ListItemIcon>
                                <FileCopy />
                              </ListItemIcon>
                              <ListItemText primary={m.filename.slice(10, -4)} />
                            </ListItem>
                          ))
                        }
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={9} md={9} xs={12}>
                  <Box sx={{ mt: 0 }}>
                    <Card>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography variant="h4" className={classes.title}>
                              { currentName.slice(10, -4) }
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            {
                              currentName !== ''
                                ? (
                                  <Button color="secondary" variant="contained" size="medium" onClick={() => descargar()}>
                                    Descargar
                                  </Button>
                                ) : ("")
                            }
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                  <ShowRenderFile typeFile={tipoArchivo} />
                </Grid>
              </Grid>
            </Box>
          </Container>
        </DialogContent>
        {/*<DialogActions>*/}
        {/*  <Button autoFocus onClick={cerrarModal} color="primary">*/}
        {/*    Salir*/}
        {/*  </Button>*/}
        {/*  <Button color="primary">*/}
        {/*    Generar Excel*/}
        {/*  </Button>*/}
        {/*</DialogActions>*/}
      </Dialog>
      {/*<Dialog fullScreen open={open} onClose={cerrarModal} TransitionComponent={Transition}>*/}
      {/*  <AppBar className={classes.appBar}>*/}
      {/*    <Toolbar>*/}
      {/*      <IconButton edge="start" color="inherit" onClick={cerrarModal} aria-label="close">*/}
      {/*        <CloseIcon />*/}
      {/*      </IconButton>*/}
      {/*      <Typography variant="h6" className={classes.title}>*/}
      {/*        Sound*/}
      {/*      </Typography>*/}
      {/*      <Button autoFocus color="inherit" onClick={cerrarModal}>*/}
      {/*        save*/}
      {/*      </Button>*/}
      {/*    </Toolbar>*/}
      {/*  </AppBar>*/}
      {/*  <List>*/}
      {/*    <ListItem button>*/}
      {/*      <ListItemText primary="Phone ringtone" secondary="Titania" />*/}
      {/*    </ListItem>*/}
      {/*    <Divider />*/}
      {/*    <ListItem button>*/}
      {/*      <ListItemText primary="Default notification ringtone" secondary="Tethys" />*/}
      {/*    </ListItem>*/}
      {/*  </List>*/}
      {/*</Dialog>*/}
    </div>
  )
}

export default ModalFiles
