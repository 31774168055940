import { useState } from 'react';
import { Formik } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid, Link,
  TextField, Typography
} from '@material-ui/core';
import * as Yup from 'yup';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { alpha } from '@material-ui/core/styles';
import { parse, format } from 'date-fns';
import backend from '../../api/backend';

// eslint-disable-next-line react/prop-types
const FormDespacho = ({ data, regresar }) => {
  const [show, setShowNotificaction] = useState(false);
  const { http } = backend
  const formatDatex = (val) => val.slice(0, 10)
  // const date = parse(ne, 'yyyy-MM-dd', new Date())
  // let newDate = format(date, "dd/MM/yyyy")
  // console.log(newDate)
  // return ne
  const handleNotification = (showx) => {
    setShowNotificaction(showx)
  }
  const modificarDespacho = (values) => {
    http.post(`api/actualizar_despacho`, values).then((resp) => {
      const r = resp.data;
      regresar()
      handleNotification(true)
    }).catch((error) => {
      console.log(error)
    }).finally(() => {
    });
  };
  return (
    <Formik
      initialValues={{ ...data }}
      validationSchema={Yup.object().shape({
        numero_tramite: Yup.string().max(255).required('El usuario es requerido'),
        ref_interna: Yup.string().max(255).required('Password es requerido')
      })}
      onSubmit={(values) => {
        modificarDespacho(values)
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader subheader="Puede editar la información" title="Editar Mercadería" />
            <Divider />
            <CardContent>
              <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.numero_tramite && errors.numero_tramite)}
                    fullWidth
                    helperText={touched.numero_tramite && errors.numero_tramite}
                    label="Número de Trámite"
                    margin="normal"
                    name="numero_tramite"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.numero_tramite}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.ref_interna && errors.ref_interna)}
                    fullWidth
                    helperText={touched.ref_interna && errors.ref_interna}
                    label="Ref. Interna"
                    margin="normal"
                    name="ref_interna"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.ref_interna}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="datetime-local"
                    label="Fecha Inicial"
                    type="date"
                    error={Boolean(touched.fecha_inicio && errors.fecha_inicio)}
                    fullWidth
                    helperText={touched.fecha_inicio && errors.fecha_inicio}
                    margin="normal"
                    name="fecha_inicio"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={formatDatex(values.fecha_inicio)}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="datetime-local"
                    label="Fecha Final"
                    type="date"
                    error={Boolean(touched.fecha_fin && errors.fecha_fin)}
                    fullWidth
                    helperText={touched.fecha_fin && errors.fecha_fin}
                    margin="normal"
                    name="fecha_fin"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={formatDatex(values.fecha_fin)}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.cliente && errors.cliente)}
                    fullWidth
                    helperText={touched.cliente && errors.cliente}
                    label="Cliente"
                    margin="normal"
                    name="cliente"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.cliente}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.detalle_mercaderia && errors.detalle_mercaderia)}
                    fullWidth
                    helperText={touched.detalle_mercaderia && errors.detalle_mercaderia}
                    label="Detalle Mercaderia"
                    margin="normal"
                    name="detalle_mercaderia"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.detalle_mercaderia}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.liquidador && errors.liquidador)}
                    fullWidth
                    helperText={touched.liquidador && errors.liquidador}
                    label="Liquidador"
                    margin="normal"
                    name="liquidador"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.liquidador}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.gestor && errors.gestor)}
                    fullWidth
                    helperText={touched.gestor && errors.gestor}
                    label="Gestor"
                    margin="normal"
                    name="gestor"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.gestor}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.nro_c && errors.nro_c)}
                    fullWidth
                    helperText={touched.nro_c && errors.nro_c}
                    label="Nro C"
                    margin="normal"
                    name="nro_c"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.nro_c}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="datetime-local"
                    label="Fecha Reg Dui"
                    type="date"
                    error={Boolean(touched.fecha_dui && errors.fecha_dui)}
                    fullWidth
                    helperText={touched.fecha_dui && errors.fecha_dui}
                    margin="normal"
                    name="fecha_dui"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={formatDatex(values.fecha_dui)}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="datetime-local"
                    label="Fecha Pago Trib"
                    type="date"
                    error={Boolean(touched.fecha_trib && errors.fecha_trib)}
                    fullWidth
                    helperText={touched.fecha_trib && errors.fecha_trib}
                    margin="normal"
                    name="fecha_trib"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={formatDatex(values.fecha_trib)}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.nro_carpeta && errors.nro_carpeta)}
                    fullWidth
                    helperText={touched.nro_carpeta && errors.nro_carpeta}
                    label="Nro Carpeta"
                    margin="normal"
                    name="nro_carpeta"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.nro_carpeta}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.patron_declaracion && errors.patron_declaracion)}
                    fullWidth
                    helperText={touched.patron_declaracion && errors.patron_declaracion}
                    label="Patrón declaración"
                    margin="normal"
                    name="patron_declaracion"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.patron_declaracion}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.aduana && errors.aduana)}
                    fullWidth
                    helperText={touched.aduana && errors.aduana}
                    label="Aduana"
                    margin="normal"
                    name="aduana"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.aduana}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.canal && errors.canal)}
                    fullWidth
                    helperText={touched.canal && errors.canal}
                    label="Canal"
                    margin="normal"
                    name="canal"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.canal}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="datetime-local"
                    label="Recepción declaración"
                    type="date"
                    error={Boolean(touched.recepcion_declaracion && errors.recepcion_declaracion)}
                    fullWidth
                    helperText={touched.recepcion_declaracion && errors.recepcion_declaracion}
                    margin="normal"
                    name="recepcion_declaracion"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={formatDatex(values.recepcion_declaracion)}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="datetime-local"
                    label="Presentación Carpeta"
                    type="date"
                    error={Boolean(touched.presentacion_carpeta && errors.presentacion_carpeta)}
                    fullWidth
                    helperText={touched.presentacion_carpeta && errors.presentacion_carpeta}
                    margin="normal"
                    name="presentacion_carpeta"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={formatDatex(values.presentacion_carpeta)}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.asignacion_vista && errors.asignacion_vista)}
                    fullWidth
                    helperText={touched.asignacion_vista && errors.asignacion_vista}
                    label="Asignación Vista"
                    margin="normal"
                    name="asignacion_vista"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.asignacion_vista}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.aforo_importacion && errors.aforo_importacion)}
                    fullWidth
                    helperText={touched.aforo_importacion && errors.aforo_importacion}
                    label="Aforo Importacion"
                    margin="normal"
                    name="aforo_importacion"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.aforo_importacion}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.diligencia && errors.diligencia)}
                    fullWidth
                    helperText={touched.diligencia && errors.diligencia}
                    label="Diligencia"
                    margin="normal"
                    name="diligencia"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.diligencia}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.notificacion_imp && errors.notificacion_imp)}
                    fullWidth
                    helperText={touched.notificacion_imp && errors.notificacion_imp}
                    label="Notificacción Imp"
                    margin="normal"
                    name="notificacion_imp"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.notificacion_imp}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.evaluacion_resultado && errors.evaluacion_resultado)}
                    fullWidth
                    helperText={touched.evaluacion_resultado && errors.evaluacion_resultado}
                    label="Evaluación Resultado"
                    margin="normal"
                    name="evaluacion_resultado"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.evaluacion_resultado}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.resp_diligencia && errors.resp_diligencia)}
                    fullWidth
                    helperText={touched.resp_diligencia && errors.resp_diligencia}
                    label="Resp Diligencia"
                    margin="normal"
                    name="resp_diligencia"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.resp_diligencia}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.si_no_descargo && errors.si_no_descargo)}
                    fullWidth
                    helperText={touched.si_no_descargo && errors.si_no_descargo}
                    label="Acepta Niega Descargo"
                    margin="normal"
                    name="si_no_descargo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.si_no_descargo}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="datetime-local"
                    label="Levante Mercadería"
                    type="date"
                    error={Boolean(touched.levante_mercaderia && errors.levante_mercaderia)}
                    fullWidth
                    helperText={touched.levante_mercaderia && errors.levante_mercaderia}
                    margin="normal"
                    name="levante_mercaderia"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={formatDatex(values.levante_mercaderia)}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="datetime-local"
                    label="Control Documento"
                    type="date"
                    error={Boolean(touched.control_documento && errors.control_documento)}
                    fullWidth
                    helperText={touched.control_documento && errors.control_documento}
                    margin="normal"
                    name="control_documento"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={formatDatex(values.control_documento)}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="datetime-local"
                    label="Pago Almacenaje"
                    type="date"
                    error={Boolean(touched.pago_almacenaje && errors.pago_almacenaje)}
                    fullWidth
                    helperText={touched.pago_almacenaje && errors.pago_almacenaje}
                    margin="normal"
                    name="pago_almacenaje"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={formatDatex(values.pago_almacenaje)}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="datetime-local"
                    label="Retiro de Mercadería"
                    type="date"
                    error={Boolean(touched.retiro_mercaderia && errors.retiro_mercaderia)}
                    fullWidth
                    helperText={touched.retiro_mercaderia && errors.retiro_mercaderia}
                    margin="normal"
                    name="retiro_mercaderia"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={formatDatex(values.retiro_mercaderia)}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.estado_tramite && errors.estado_tramite)}
                    fullWidth
                    helperText={touched.estado_tramite && errors.estado_tramite}
                    label="Estado Trámite"
                    margin="normal"
                    name="estado_tramite"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.estado_tramite}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
              <Button color="secondary" variant="contained" onClick={() => regresar()}>
                Regresar
              </Button>
              <Button color="primary" variant="contained" type="submit">
                Modificar
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default FormDespacho;
