const despachos = {
  select: [
    {
      value: 'todo',
      label: 'Todo',
      check: true
    },
    {
      value: 'aduana',
      label: 'Aduana',
      check: true
    },
    {
      value: 'aforo_importacion',
      label: 'Aforo Importación',
      check: true
    },
    {
      value: 'asignacion_vista',
      label: 'Asignación Vista',
      check: true
    },
    {
      value: 'canal',
      label: 'Canal',
      check: true
    },
    {
      value: 'cliente',
      label: 'Cliente',
      check: true
    },
    {
      value: 'control_documento',
      label: 'Control Documento',
      check: true
    },
    {
      value: 'created_at',
      label: 'Fecha de Creación',
      check: true
    },
    {
      value: 'detalle_mercaderia',
      label: 'Detalle de Mercadería',
      check: true
    },
    {
      value: 'diligencia',
      label: 'Diligencia',
      check: true
    },
    {
      value: 'estado_tramite',
      label: 'Estado Trámite',
      check: true
    },
    {
      value: 'evaluacion_resultado',
      label: 'Evaluación Resultado',
      check: true
    },
    {
      value: 'gestor',
      label: 'Gestor',
      check: true
    },
    {
      value: 'id',
      label: 'Id',
      check: true
    },
    {
      value: 'levante_mercaderia',
      label: 'Levante Mercadería',
      check: true
    },
    {
      value: 'liquidador',
      label: 'Liquidador',
      check: true
    },
    {
      value: 'notificacion_imp',
      label: 'Notificación Imp',
      check: true
    },
    {
      value: 'nro_c',
      label: 'Nro C',
      check: true
    },
    {
      value: 'nro_carpeta',
      label: 'Nro Carpeta',
      check: true
    },
    {
      value: 'numero_tramite',
      label: 'Número de Trámite',
      check: true
    },
    {
      value: 'pago_almacenaje',
      label: 'Pago Almacenaje',
      check: true
    },
    {
      value: 'patron_declaracion',
      label: 'Patrón Declaración',
      check: true
    },
    {
      value: 'presentacion_carpeta',
      label: 'Presentación Carpeta',
      check: true
    },
    {
      value: 'recepcion_declaracion',
      label: 'Recepción Declaración',
      check: true
    },
    {
      value: 'ref_interna',
      label: 'Ref. Interna',
      check: true
    },
    {
      value: 'registro_id',
      label: 'Registro Id',
      check: true
    },
    {
      value: 'resp_diligencia',
      label: 'Resp Diligencia',
      check: true
    },
    {
      value: 'retiro_mercaderia',
      label: 'Retiro Mercadería',
      check: true
    },
    {
      value: 'si_no_descargo',
      label: 'Descargo',
      check: true
    },
  ],
  selectExport: [
    {
      value: 'aduana',
      label: 'Aduana',
      check: true
    },
    {
      value: 'aforo_importacion',
      label: 'Aforo Importación',
      check: true
    },
    {
      value: 'asignacion_vista',
      label: 'Asignación Vista',
      check: true
    },
    {
      value: 'canal',
      label: 'Canal',
      check: true
    },
    {
      value: 'cliente',
      label: 'Cliente',
      check: true
    },
    {
      value: 'control_documento',
      label: 'Control Documento',
      check: true
    },
    {
      value: 'created_at',
      label: 'Fecha de Creación',
      check: true
    },
    {
      value: 'detalle_mercaderia',
      label: 'Detalle de Mercadería',
      check: true
    },
    {
      value: 'diligencia',
      label: 'Diligencia',
      check: true
    },
    {
      value: 'estado_tramite',
      label: 'Estado Trámite',
      check: true
    },
    {
      value: 'evaluacion_resultado',
      label: 'Evaluación Resultado',
      check: true
    },
    {
      value: 'gestor',
      label: 'Gestor',
      check: true
    },
    {
      value: 'id',
      label: 'Id',
      check: true
    },
    {
      value: 'levante_mercaderia',
      label: 'Levante Mercadería',
      check: true
    },
    {
      value: 'liquidador',
      label: 'Liquidador',
      check: true
    },
    {
      value: 'notificacion_imp',
      label: 'Notificación Imp',
      check: true
    },
    {
      value: 'nro_c',
      label: 'Nro C',
      check: true
    },
    {
      value: 'nro_carpeta',
      label: 'Nro Carpeta',
      check: true
    },
    {
      value: 'numero_tramite',
      label: 'Número de Trámite',
      check: true
    },
    {
      value: 'pago_almacenaje',
      label: 'Pago Almacenaje',
      check: true
    },
    {
      value: 'patron_declaracion',
      label: 'Patrón Declaración',
      check: true
    },
    {
      value: 'presentacion_carpeta',
      label: 'Presentación Carpeta',
      check: true
    },
    {
      value: 'recepcion_declaracion',
      label: 'Recepción Declaración',
      check: true
    },
    {
      value: 'ref_interna',
      label: 'Ref. Interna',
      check: true
    },
    {
      value: 'registro_id',
      label: 'Registro Id',
      check: true
    },
    {
      value: 'resp_diligencia',
      label: 'Resp Diligencia',
      check: true
    },
    {
      value: 'retiro_mercaderia',
      label: 'Retiro Mercadería',
      check: true
    },
    {
      value: 'si_no_descargo',
      label: 'Descargo',
      check: true
    },
  ],
  selectFecha: [
    {
      value: 'fecha_dui',
      label: 'Fecha Dui',
      check: true
    },
    {
      value: 'fecha_fin',
      label: 'Fecha Fin',
      check: true
    },
    {
      value: 'fecha_inicio',
      label: 'Fecha Inicio',
      check: true
    },
    {
      value: 'fecha_trib',
      label: 'Fecha Trib',
      check: true
    }
  ]
}

const liquidacionFilter = [
  { value: 'todo', label: 'Todo', check: true },
  { value: 'item', label: 'Item', check: false },
  { value: 'hsc', label: 'Hsc', check: false },
  { value: 'descripcion', label: 'Descripción', check: false },
  { value: 'marca', label: 'Marca', check: false },
  { value: 'tipo', label: 'Tipo', check: false },
  { value: 'clase', label: 'Clase', check: false },
  { value: 'modelo', label: 'Modelo', check: false },
  { value: 'cuantitativo1', label: 'Cuantitativo 1', check: false },
  { value: 'cuantitativo2', label: 'Cuantitativo 2', check: false },
  { value: 'forma_presentacion', label: 'Forma Presentación', check: false },
  { value: 'naturaleza', label: 'Naturaleza', check: false },
  { value: 'uso_aplicacion', label: 'Uso Aplicación', check: false },
  { value: 'otras_caracteristicas', label: 'Otras Características', check: false },
  { value: 'pais_origen', label: 'País de orígen', check: false },
  { value: 'tariff_num', label: 'Tariff', check: false },
  { value: 'um', label: 'Um', check: false },
  { value: 'cert', label: 'Cert', check: false },
  { value: 'desp_front', label: 'Desp front', check: false },
  { value: 'certificado_origen', label: 'Certif. Orígen', check: false },
  { value: 'cantidad', label: 'Cantidad', check: false },
  { value: 'precio_unitario', label: 'Precio Unitario', check: false },
  { value: 'fob_me', label: 'Fob Me', check: false },
  { value: 'fob_usd', label: 'Fob Usd', check: false },
  { value: 'flete_usd', label: 'Flete Usd', check: false },
  { value: 'seguro_usd', label: 'Seguro Usd', check: false },
  { value: 'gp_usd', label: 'Gp Usd', check: false },
  { value: 'og_usd', label: 'Og Usd', check: false },
  { value: 'cif_usd', label: 'Cif Usd', check: false },
  { value: 'cif_bob', label: 'Cif Bob', check: false },
  { value: 'ga_bs', label: 'Ga Bs', check: false },
  { value: 'ga_usd', label: 'Ga Usd', check: false },
  { value: 'iva_bs', label: 'Iva Bs', check: false },
  { value: 'iva_usd', label: 'Iva Usd', check: false },
  { value: 'bultos_packages', label: 'Bultos Pack', check: false },
  { value: 'segun_peso_neto_gross', label: 'Segun peso neto gross', check: false },
  { value: 'segun_peso_neto_net', label: 'Según peso neto net', check: false },
  { value: 'incoterms', label: 'Incoterms', check: false },
  { value: 'declaracion', label: 'Declaración', check: false },
  { value: 'fecha_validacion', label: 'Fecha Validación', check: false },
  { value: 'aduana', label: 'Aduana', check: false },
  { value: 'pais_exp', label: 'País Exp', check: false },
  { value: 'tipo_despacho', label: 'Tipo despacho', check: false },
  { value: 'modalidad', label: 'Modalidad', check: false },
  { value: 'fecha_de_pago', label: 'Fecha de pago', check: false },
  { value: 'fecha_de_salida_aduana', label: 'Fecha de salida aduana', check: false },
  { value: 'medio_de_transporte', label: 'Medio de transporte', check: false },
  { value: 'fecha_de_embarque', label: 'Fecha de embarque', check: false },
  { value: 'numero_doc_embarque', label: 'Numero doc embarque', check: false },
  { value: 'fecha_llegada', label: 'Fecha llegada', check: false },
  { value: 'emisor_almacenera', label: 'Emisor almacenera', check: false },
  { value: 'prm', label: 'Prm', check: false },
  { value: 'fecha', label: 'Fecha', check: false },
  { value: 'division', label: 'Division', check: false },
  { value: 'fecha_retiro_prm', label: 'Decha retiro prm', check: false },
  { value: 'fecha_examen_previo', label: 'Fecha examen previo', check: false },
  { value: 'fecha_envio_de_factura', label: 'Fecha envio de factura', check: false },
  { value: 'fecha_aprobacion_factura', label: 'Fecha aprobacion factura', check: false },
  { value: 'fecha_envio_legajo_documentos', label: 'Fecha envio legajo documentos', check: false },
  { value: 'nombre_proveedor', label: 'Nombre proveedor', check: false },
  { value: 'canal_asignado', label: 'Canal asignado', check: false },
  { value: 'pais_de_fabricacion', label: 'Pais de fabricacion', check: false },
  { value: 'tipo_moneda_factura_comercial', label: 'Tipo moneda factura comercial', check: false },
  { value: 'observaciones', label: 'Observaciones', check: false }
]

const liquidacionFilterExport = [
  { value: 'item', label: 'Item', check: false },
  { value: 'hsc', label: 'Hsc', check: false },
  { value: 'descripcion', label: 'Descripción', check: false },
  { value: 'marca', label: 'Marca', check: false },
  { value: 'tipo', label: 'Tipo', check: false },
  { value: 'clase', label: 'Clase', check: false },
  { value: 'modelo', label: 'Modelo', check: false },
  { value: 'cuantitativo1', label: 'Cuantitativo 1', check: false },
  { value: 'cuantitativo2', label: 'Cuantitativo 2', check: false },
  { value: 'forma_presentacion', label: 'Forma Presentación', check: false },
  { value: 'naturaleza', label: 'Naturaleza', check: false },
  { value: 'uso_aplicacion', label: 'Uso Aplicación', check: false },
  { value: 'otras_caracteristicas', label: 'Otras Características', check: false },
  { value: 'pais_origen', label: 'País de orígen', check: false },
  { value: 'tariff_num', label: 'Tariff', check: false },
  { value: 'um', label: 'Um', check: false },
  { value: 'cert', label: 'Cert', check: false },
  { value: 'desp_front', label: 'Desp front', check: false },
  { value: 'certificado_origen', label: 'Certif. Orígen', check: false },
  { value: 'cantidad', label: 'Cantidad', check: false },
  { value: 'precio_unitario', label: 'Precio Unitario', check: false },
  { value: 'fob_me', label: 'Fob Me', check: false },
  { value: 'fob_usd', label: 'Fob Usd', check: false },
  { value: 'flete_usd', label: 'Flete Usd', check: false },
  { value: 'seguro_usd', label: 'Seguro Usd', check: false },
  { value: 'gp_usd', label: 'Gp Usd', check: false },
  { value: 'og_usd', label: 'Og Usd', check: false },
  { value: 'cif_usd', label: 'Cif Usd', check: false },
  { value: 'cif_bob', label: 'Cif Bob', check: false },
  { value: 'ga_bs', label: 'Ga Bs', check: false },
  { value: 'ga_usd', label: 'Ga Usd', check: false },
  { value: 'iva_bs', label: 'Iva Bs', check: false },
  { value: 'iva_usd', label: 'Iva Usd', check: false },
  { value: 'bultos_packages', label: 'Bultos Pack', check: false },
  { value: 'segun_peso_neto_gross', label: 'Segun peso neto gross', check: false },
  { value: 'segun_peso_neto_net', label: 'Según peso neto net', check: false },
  { value: 'incoterms', label: 'Incoterms', check: false },
  { value: 'declaracion', label: 'Declaración', check: false },
  { value: 'fecha_validacion', label: 'Fecha Validación', check: false },
  { value: 'aduana', label: 'Aduana', check: false },
  { value: 'pais_exp', label: 'País Exp', check: false },
  { value: 'tipo_despacho', label: 'Tipo despacho', check: false },
  { value: 'modalidad', label: 'Modalidad', check: false },
  { value: 'fecha_de_pago', label: 'Fecha de pago', check: false },
  { value: 'fecha_de_salida_aduana', label: 'Fecha de salida aduana', check: false },
  { value: 'medio_de_transporte', label: 'Medio de transporte', check: false },
  { value: 'fecha_de_embarque', label: 'Fecha de embarque', check: false },
  { value: 'numero_doc_embarque', label: 'Numero doc embarque', check: false },
  { value: 'fecha_llegada', label: 'Fecha llegada', check: false },
  { value: 'emisor_almacenera', label: 'Emisor almacenera', check: false },
  { value: 'prm', label: 'Prm', check: false },
  { value: 'fecha', label: 'Fecha', check: false },
  { value: 'division', label: 'Division', check: false },
  { value: 'fecha_retiro_prm', label: 'Decha retiro prm', check: false },
  { value: 'fecha_examen_previo', label: 'Fecha examen previo', check: false },
  { value: 'fecha_envio_de_factura', label: 'Fecha envio de factura', check: false },
  { value: 'fecha_aprobacion_factura', label: 'Fecha aprobacion factura', check: false },
  { value: 'fecha_envio_legajo_documentos', label: 'Fecha envio legajo documentos', check: false },
  { value: 'nombre_proveedor', label: 'Nombre proveedor', check: false },
  { value: 'canal_asignado', label: 'Canal asignado', check: false },
  { value: 'pais_de_fabricacion', label: 'Pais de fabricacion', check: false },
  { value: 'tipo_moneda_factura_comercial', label: 'Tipo moneda factura comercial', check: false },
  { value: 'observaciones', label: 'Observaciones', check: false }
]

const mercaderiaFilter = [
  { value: 'todo', label: 'Todo', check: false },
  { value: 'clase', label: 'Clase', check: false },
  { value: 'cuantitativo1', label: 'Cuantitativo 1', check: false },
  { value: 'cuantitativo2', label: 'Cuantitativo 2', check: false },
  { value: 'descripcion', label: 'Descripción', check: false },
  { value: 'forma_presentacion', label: 'Forma Presentacion', check: false },
  { value: 'hsc', label: 'HSC', check: false },
  { value: 'id', label: 'id', check: false },
  { value: 'item', label: 'Item', check: false },
  { value: 'marca', label: 'Marca', check: false },
  { value: 'modelo', label: 'Modelo', check: false },
  { value: 'naturaleza', label: 'Naturaleza', check: false },
  { value: 'otras_caracteristicas', label: 'Otras Caracteristicas', check: false },
  { value: 'pais_origen', label: 'Pais Origen', check: false },
  { value: 'registro_id', label: 'Registro Id', check: false },
  { value: 'tipo', label: 'Tipo', check: false },
  { value: 'uso_aplicacion', label: 'Uso Aplicacion', check: false }
]

const mercaderiaExport = [
  { value: 'clase', label: 'Clase', check: false },
  { value: 'cuantitativo1', label: 'Cuantitativo 1', check: false },
  { value: 'cuantitativo2', label: 'Cuantitativo 2', check: false },
  { value: 'descripcion', label: 'Descripción', check: false },
  { value: 'forma_presentacion', label: 'Forma Presentacion', check: false },
  { value: 'hsc', label: 'HSC', check: false },
  { value: 'id', label: 'id', check: false },
  { value: 'item', label: 'Item', check: false },
  { value: 'marca', label: 'Marca', check: false },
  { value: 'modelo', label: 'Modelo', check: false },
  { value: 'naturaleza', label: 'Naturaleza', check: false },
  { value: 'otras_caracteristicas', label: 'Otras Caracteristicas', check: false },
  { value: 'pais_origen', label: 'Pais Origen', check: false },
  { value: 'registro_id', label: 'Registro Id', check: false },
  { value: 'tipo', label: 'Tipo', check: false },
  { value: 'uso_aplicacion', label: 'Uso Aplicacion', check: false }
]

export { despachos, liquidacionFilter, mercaderiaFilter, mercaderiaExport, liquidacionFilterExport }
