import { useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  Grid,
  Alert
} from '@material-ui/core';
import backend from '../api/backend';

const { http, setLocalJwt } = backend

const Register = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [message, setMessage] = useState("")

  const registerUser = (values) => {
    setStatus(true)
    setErrorMessage(false)
    http.post('api/register', values).then((resp) => {
      const r = resp.data;
      if (!!r.access_token) {
        setLocalJwt(r.access_token)
        navigate('/app/dashboard', { replace: true })
      } else {
        setErrorMessage(true)
        setMessage(r.message)
      }
    }).catch((error) => {
      console.log(error)
    }).finally(() => {
      setStatus(false)
    });
  }

  return (
    <>
      <Helmet>
        <title>Registrar Usuario</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="md">
          <Formik
            initialValues={{
              email: '',
              fullname: '',
              correo: '',
              password: '',
              nit: '',
              company_name: '',
              cellphone: '',
              foto: '',
              tipoUser: 1,
              estado: 'ACT'
            }}
            validationSchema={
              Yup.object().shape({
                correo: Yup.string().email('Debe ser un correo válido').max(255).required('Correo es requerido'),
                fullname: Yup.string().max(255).required('El nombre es requerido'),
                email: Yup.string().max(255).required('El usuario es requerido'),
                password: Yup.string().max(255).required('Password es requerido'),
              })
            }
            onSubmit={(values) => {
              registerUser(values)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Crear nueva cuenta
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Ingrese los datos correctos
                  </Typography>
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={Boolean(touched.fullname && errors.fullname)}
                      fullWidth
                      helperText={touched.fullname && errors.fullname}
                      label="Nombre completo"
                      margin="normal"
                      name="fullname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.fullname}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.correo && errors.correo)}
                      fullWidth
                      helperText={touched.correo && errors.correo}
                      label="Correo electrónico"
                      margin="normal"
                      name="correo"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.correo}
                      variant="outlined"
                    />
                    <TextField
                      fullWidth
                      label="Nit"
                      margin="normal"
                      name="nit"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.nit}
                      variant="outlined"
                    />
                    <TextField
                      fullWidth
                      label="Nombre empresa"
                      margin="normal"
                      name="company_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.company_name}
                      variant="outlined"
                    />
                    <TextField
                      fullWidth
                      label="Celular"
                      margin="normal"
                      name="cellphone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values.cellphone}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Usuario"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Contraseña"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                    <Box sx={{ py: 2 }}>
                      <Button
                        color="primary"
                        disabled={status}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        { status ? 'Registrando ...' : 'Registrarse'}
                      </Button>
                    </Box>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      tengo una cuenta?
                      {' '}
                      <Link
                        component={RouterLink}
                        to="/login"
                        variant="h6"
                      >
                        Ingresar
                      </Link>
                    </Typography>
                    { errorMessage ? <Alert severity="error">{message}</Alert> : ""}
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Register;
