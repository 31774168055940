import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon
} from 'react-feather';
import NavItem from './NavItem';
import backend from '../api/backend';

const {
  getMenu, getUser
} = backend

const user = {
  avatar: '/static/images/avatars/default.png',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith'
};

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'BarChartIcon'
  },
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'UsersIcon'
  },
  {
    href: '/app/products',
    icon: ShoppingBagIcon,
    title: 'ShoppingBagIcon'
  },
  {
    href: '/app/account',
    icon: UserIcon,
    title: 'UserIcon'
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'SettingsIcon'
  },
  {
    href: '/login',
    icon: LockIcon,
    title: 'LockIcon'
  },
  {
    href: '/register',
    icon: UserPlusIcon,
    title: 'UserPlusIcon'
  },
  {
    href: '/404',
    icon: AlertCircleIcon,
    title: 'AlertCircleIcon'
  }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const { http } = backend
  const location = useLocation();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const [menu, setMenu] = useState([])
  const [userx, setUserx] = useState([])
  const [laImagen, setLaImagen] = useState()
  let numero = 1

  const getImagePerfil = (elIde) => {
    http.get(`api/show_file_perfil/${elIde}`).then((resp) => {
      const r = resp.data;
      let cadena = `data:image/png;base64, ${r.file}`
      setLaImagen(cadena)
    }).catch((error) => {
      console.log(error)
    })
  }
  useEffect(() => {
    let r = getMenu()
    let s = getUser()
    setMenu(r)
    setUserx(s)
    getImagePerfil(s.id)
  }, [numero]);

  const content = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', p: 2 }}>
        <Avatar
          component={RouterLink}
          src={laImagen}
          sx={{ cursor: 'pointer', width: 64, height: 64 }}
          to="/app/account"
        />
        <Typography color="textPrimary" variant="h5">
          {userx.fullname}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {userx.correo}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {menu.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={items.find((x) => x.title === item.icon).icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default DashboardSidebar;
