import { v4 as uuid } from 'uuid';

export default [
  {
    id: 'mercaderia',
    createdAt: '27/03/2019',
    description: 'Lista Descripcion de mercaderías',
    media: '/static/images/products/product_1.png',
    title: 'Mercadería por Partida Arancelaria',
    totalDownloads: 0
  },
  {
    id: 'despachos',
    createdAt: '31/03/2019',
    description: 'Despachos Realizados',
    media: '/static/images/products/product_2.png',
    title: 'Tabla Despachos Realizados',
    totalDownloads: 0
  },
  {
    id: 'liquidacion',
    createdAt: '31/03/2019',
    description: 'Tabla Liquidacion',
    media: '/static/images/products/product_3.png',
    title: 'Lista Tabla Liquidación',
    totalDownloads: 0
  }
];
