import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon, Grid
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const onKeyUpValue = (value) => {
  console.log(value.target.value)
}

const ClientesToolbar = ({ esCrear, filterTable }) => (
  <Box>
    <Box sx={{ mt: 0 }}>
      <Card>
        <CardContent>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Buscar Cliente"
                variant="outlined"
                size="small"
                onKeyUp={filterTable}
              />
            </Grid>
            <Grid item>
              <Button color="primary" variant="contained" size="small" onClick={() => esCrear('crear')}>
                Agregar Cliente
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  </Box>
)
export default ClientesToolbar;
