import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const ProductListToolbar = (props) => (
  <Box {...props}>
    {/*<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>*/}
    {/*  <Button>*/}
    {/*    Import*/}
    {/*  </Button>*/}
    {/*  <Button sx={{ mx: 1 }}>*/}
    {/*    Export*/}
    {/*  </Button>*/}
    {/*  <Button*/}
    {/*    color="primary"*/}
    {/*    variant="contained"*/}
    {/*  >*/}
    {/*    Add product*/}
    {/*  </Button>*/}
    {/*</Box>*/}
    <Box sx={{ mt: 0 }}>
      <Card>
        <CardContent>
          <Box sx={{ maxWidth: 500 }}>
            <Button color="secondary" variant="contained">Importar Excel</Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  </Box>
);

export default ProductListToolbar;
