import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Pagination,
  LinearProgress
} from '@material-ui/core';
import { Edit, FileUpload, Cloud, Delete } from '@material-ui/icons';
import backend from '../../api/backend';
import ModalFiles from './ModalFiles';
import al from '../../mixins/alertas'
import { formatDateEs } from '../../mixins/helpers';

const fechasLiquidacion = [
  'fecha_validacion', 'fecha_de_pago', 'fecha_de_salida_aduana', 'fecha_de_embarque',
  'fecha_llegada', 'fecha_retiro_prm', 'fecha_examen_previo', 'fecha_envio_de_factura',
  'fecha_aprobacion_factura', 'fecha_envio_legajo_documentos'
]
const DataTableLiquidacion = ({ mercaderia, changeMode, soloLectura, pagination, setPagex, eliminar }) => {
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const { http } = backend
  const handlePageChange = (event, newPage) => {
    setPagex(newPage);
  };

  function FileUploadDos({ idMercaderia }) {
    const hiddenFileInput = useRef(null)

    const handleClick = (event) => {
      hiddenFileInput.current.click()
    }

    function handleUpload(event) {
      let fil = event.target.files[0]
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData()
      formData.append('file', fil)
      al.openLoading("Subiendo")
      http.post(`api/upload_file_liquidacion/${idMercaderia}`, formData, config).then((resp) => {
        const r = resp.data;
        al.closeLoading()
        al.openSuccess()
      }).catch((error) => {
        al.closeLoading()
        al.openError()
      })
    }
    return (
      <div id="upload-box">
        <IconButton color="secondary" aria-label="upload" size="small" onClick={handleClick}>
          <FileUpload fontSize="inherit" />
        </IconButton>
        <input ref={hiddenFileInput} onChange={handleUpload} style={{ display: "none" }} type="file" />
      </div>
    )
  }

  const FilaTable = ({ data }) => {
    let narr = []
    let items = [1, 2, 3, 4, 5]
    Object.entries(data).forEach(([x, y]) => {
      narr.push({
        clave: x,
        valor: y
      })
    })
    let num = 0
    return (
      items.map((x) => {
        num += 13
        return (
          <TableCell style={{ width: '20%' }} key={x + 10}>
            {
              narr.slice(num - 13, num).map((el) => {
                if (fechasLiquidacion.includes(el.clave)) {
                  return (
                    <small key={x + el.clave}>
                      <strong>{el.clave}</strong>: {!!el.valor && formatDateEs(el.valor)}<br />
                    </small>
                  )
                }
                return (
                  <small key={x + el.clave}>
                    <strong>{el.clave}</strong>: {el.valor}<br />
                  </small>
                )
              })
            }
          </TableCell>
        )
      })
    )
  }

  return (
    <Card>
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableBody>
              {mercaderia.map((m, index) => (
                <TableRow hover key={m.id} selected={selectedCustomerIds.indexOf(m.id) !== -1}>
                  <FilaTable data={m} />
                  {
                    soloLectura
                      ? (
                        <>
                          <TableCell>
                            <IconButton color="primary" aria-label="delete" size="small" onClick={() => changeMode(m)}>
                              <Edit fontSize="inherit" />
                            </IconButton>
                            <FileUploadDos idMercaderia={m.id} />
                            <ModalFiles ide={m.id} />
                            <IconButton color="secondary" aria-label="delete" size="small" onClick={() => eliminar(m)}>
                              <Delete fontSize="inherit" />
                            </IconButton>
                          </TableCell>
                          {/*<TableCell>*/}
                          {/*  <IconButton color="secondary" aria-label="delete" size="small" onClick={() => eliminar(m)}>*/}
                          {/*    <Delete fontSize="inherit" />*/}
                          {/*  </IconButton>*/}
                          {/*</TableCell>*/}
                        </>
                      ) : (
                        <TableCell>
                          <ModalFiles ide={m.id} />
                        </TableCell>
                      )
                  }
                </TableRow>
              ))}
              {/*{mercaderia.length <= 0 && (*/}
              {/*  <TableRow>*/}
              {/*    <TableCell colSpan={6}>*/}
              {/*      <LinearProgress />*/}
              {/*    </TableCell>*/}
              {/*  </TableRow>*/}
              {/*)}*/}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <div style={{ textAlign: 'center' }}>
        <Pagination color="primary" count={pagination.total} page={pagination.current_page} onChange={handlePageChange} />
      </div>
    </Card>
  );
};

DataTableLiquidacion.propTypes = {
  mercaderia: PropTypes.array.isRequired,
  changeMode: PropTypes.func
};

export default DataTableLiquidacion;
