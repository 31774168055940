import XLSX from "xlsx";

export default {
  objectToExcel(obj, name, sheetName) {
    let listWB = XLSX.utils.json_to_sheet(obj);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, listWB, sheetName);
    XLSX.writeFile(wb, `${name}.xlsx`)
  }
};
