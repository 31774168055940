import { useState } from 'react';
import { Formik } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid, Link,
  TextField, Typography
} from '@material-ui/core';
import * as Yup from 'yup';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { alpha } from '@material-ui/core/styles';
import { parse, format } from 'date-fns';
import backend from '../../api/backend';

// eslint-disable-next-line react/prop-types
const FormLiquidacion = ({ data, regresar }) => {
  const [show, setShowNotificaction] = useState(false);
  const { http } = backend
  // const formatDatex = (val) => val.slice(0, 10)
  // const date = parse(ne, 'yyyy-MM-dd', new Date())
  // let newDate = format(date, "dd/MM/yyyy")
  // console.log(newDate)
  // return ne
  const handleNotification = (showx) => {
    setShowNotificaction(showx)
  }
  const modificarLiquidacion = (values) => {
    http.post(`api/actualizar_liquidacion`, values).then((resp) => {
      const r = resp.data;
      handleNotification(true)
      regresar()
    }).catch((error) => {
      console.log(error)
    })
  };
  return (
    <Formik
      initialValues={{ ...data }}
      validationSchema={Yup.object().shape({
        item: Yup.string().max(255).required('El usuario es requerido'),
      })}
      onSubmit={(values) => {
        modificarLiquidacion(values)
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader subheader="Puede editar la información" title="Editar Liquidación" />
            <Divider />
            <CardContent>
              <Grid container spacing={1}>
                {/*Mercaderia*/}
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.item && errors.item)}
                    fullWidth
                    helperText={touched.item && errors.item}
                    label="Item"
                    margin="normal"
                    name="item"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.item}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.hsc && errors.hsc)}
                    fullWidth
                    helperText={touched.hsc && errors.hsc}
                    label="H.S.C"
                    margin="normal"
                    name="hsc"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.hsc}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.descripcion && errors.descripcion)}
                    fullWidth
                    helperText={touched.descripcion && errors.descripcion}
                    label="Descripción"
                    margin="normal"
                    name="descripcion"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.descripcion}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.marca && errors.marca)}
                    fullWidth
                    helperText={touched.marca && errors.marca}
                    label="Marca"
                    margin="normal"
                    name="marca"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.marca}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.clase && errors.clase)}
                    fullWidth
                    helperText={touched.clase && errors.clase}
                    label="Clase"
                    margin="normal"
                    name="clase"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.clase}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.modelo && errors.modelo)}
                    fullWidth
                    helperText={touched.modelo && errors.modelo}
                    label="Modelo"
                    margin="normal"
                    name="modelo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.modelo}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.cuantitativo1 && errors.cuantitativo1)}
                    fullWidth
                    helperText={touched.cuantitativo1 && errors.cuantitativo1}
                    label="Cuantitativo 1"
                    margin="normal"
                    name="cuantitativo1"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.cuantitativo1}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.cuantitativo2 && errors.cuantitativo2)}
                    fullWidth
                    helperText={touched.cuantitativo2 && errors.cuantitativo2}
                    label="Cuantitativo 2"
                    margin="normal"
                    name="cuantitativo2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.cuantitativo2}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.forma_presentacion && errors.forma_presentacion)}
                    fullWidth
                    helperText={touched.forma_presentacion && errors.forma_presentacion}
                    label="Forma de Presentación"
                    margin="normal"
                    name="forma_presentacion"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.forma_presentacion}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.naturaleza && errors.naturaleza)}
                    fullWidth
                    helperText={touched.naturaleza && errors.naturaleza}
                    label="Naturaleza"
                    margin="normal"
                    name="naturaleza"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.naturaleza}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.uso_aplicacion && errors.uso_aplicacion)}
                    fullWidth
                    helperText={touched.uso_aplicacion && errors.uso_aplicacion}
                    label="Uso o Aplicación"
                    margin="normal"
                    name="uso_aplicacion"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.uso_aplicacion}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.otras_caracteristicas && errors.otras_caracteristicas)}
                    fullWidth
                    helperText={touched.otras_caracteristicas && errors.otras_caracteristicas}
                    label="Otras Características"
                    margin="normal"
                    name="otras_caracteristicas"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.otras_caracteristicas}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.pais_origen && errors.pais_origen)}
                    fullWidth
                    helperText={touched.pais_origen && errors.pais_origen}
                    label="Pais de Orígen"
                    margin="normal"
                    name="pais_origen"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.pais_origen}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                {/*endmercaderia*/}
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.tariff_num && errors.tariff_num)}
                    fullWidth
                    helperText={touched.tariff_num && errors.tariff_num}
                    label="Tariff Num"
                    margin="normal"
                    name="tariff_num"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.tariff_num}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.um && errors.um)}
                    fullWidth
                    helperText={touched.um && errors.um}
                    label="Um"
                    margin="normal"
                    name="um"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.um}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.cert && errors.cert)}
                    fullWidth
                    helperText={touched.cert && errors.cert}
                    label="Cert"
                    margin="normal"
                    name="cert"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.cert}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.desp_front && errors.desp_front)}
                    fullWidth
                    helperText={touched.desp_front && errors.desp_front}
                    label="Desp Front"
                    margin="normal"
                    name="desp_front"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.desp_front}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.certificado_origen && errors.certificado_origen)}
                    fullWidth
                    helperText={touched.certificado_origen && errors.certificado_origen}
                    label="Certificados de Origen"
                    margin="normal"
                    name="certificado_origen"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.certificado_origen}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.cantidad && errors.cantidad)}
                    fullWidth
                    helperText={touched.cantidad && errors.cantidad}
                    label="Cantidad"
                    margin="normal"
                    name="cantidad"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.cantidad}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.precio_unitario && errors.precio_unitario)}
                    fullWidth
                    helperText={touched.precio_unitario && errors.precio_unitario}
                    label="Precio Unitario"
                    margin="normal"
                    name="precio_unitario"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.precio_unitario}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.fob_me && errors.fob_me)}
                    fullWidth
                    helperText={touched.fob_me && errors.fob_me}
                    label="Fob Me"
                    margin="normal"
                    name="fob_me"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.fob_me}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.fob_usd && errors.fob_usd)}
                    fullWidth
                    helperText={touched.fob_usd && errors.fob_usd}
                    label="Fob Usd"
                    margin="normal"
                    name="fob_usd"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.fob_usd}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.flete_usd && errors.flete_usd)}
                    fullWidth
                    helperText={touched.flete_usd && errors.flete_usd}
                    label="Fob Usd"
                    margin="normal"
                    name="flete_usd"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.flete_usd}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.seguro_usd && errors.seguro_usd)}
                    fullWidth
                    helperText={touched.seguro_usd && errors.seguro_usd}
                    label="Seguro Usd"
                    margin="normal"
                    name="seguro_usd"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.seguro_usd}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.gp_usd && errors.gp_usd)}
                    fullWidth
                    helperText={touched.gp_usd && errors.gp_usd}
                    label="Gp Usd"
                    margin="normal"
                    name="gp_usd"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.gp_usd}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.og_usd && errors.og_usd)}
                    fullWidth
                    helperText={touched.og_usd && errors.og_usd}
                    label="Og Usd"
                    margin="normal"
                    name="og_usd"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.og_usd}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.cif_usd && errors.cif_usd)}
                    fullWidth
                    helperText={touched.cif_usd && errors.cif_usd}
                    label="Cif Usd"
                    margin="normal"
                    name="cif_usd"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.cif_usd}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.cif_bob && errors.cif_bob)}
                    fullWidth
                    helperText={touched.cif_bob && errors.cif_bob}
                    label="Cif Bob"
                    margin="normal"
                    name="cif_bob"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.cif_bob}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.ga_bs && errors.ga_bs)}
                    fullWidth
                    helperText={touched.ga_bs && errors.ga_bs}
                    label="Ga Bs"
                    margin="normal"
                    name="ga_bs"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.ga_bs}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.ga_usd && errors.ga_usd)}
                    fullWidth
                    helperText={touched.ga_usd && errors.ga_usd}
                    label="Ga Usd"
                    margin="normal"
                    name="ga_usd"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.ga_usd}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.iva_bs && errors.iva_bs)}
                    fullWidth
                    helperText={touched.iva_bs && errors.iva_bs}
                    label="Iva Usd"
                    margin="normal"
                    name="iva_bs"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.iva_bs}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.iva_usd && errors.iva_usd)}
                    fullWidth
                    helperText={touched.iva_usd && errors.iva_usd}
                    label="Iva Usd"
                    margin="normal"
                    name="iva_usd"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.iva_usd}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.bultos_packages && errors.bultos_packages)}
                    fullWidth
                    helperText={touched.bultos_packages && errors.bultos_packages}
                    label="Bultos Packages"
                    margin="normal"
                    name="bultos_packages"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.bultos_packages}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.segun_peso_neto_gross && errors.segun_peso_neto_gross)}
                    fullWidth
                    helperText={touched.segun_peso_neto_gross && errors.segun_peso_neto_gross}
                    label="Segun Peso Neto Gross"
                    margin="normal"
                    name="segun_peso_neto_gross"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.segun_peso_neto_gross}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.segun_peso_neto_net && errors.segun_peso_neto_net)}
                    fullWidth
                    helperText={touched.segun_peso_neto_net && errors.segun_peso_neto_net}
                    label="Segun Peso Neto Net"
                    margin="normal"
                    name="segun_peso_neto_net"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.segun_peso_neto_net}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.incoterms && errors.incoterms)}
                    fullWidth
                    helperText={touched.incoterms && errors.incoterms}
                    label="Incoterms"
                    margin="normal"
                    name="incoterms"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.incoterms}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.declaracion && errors.declaracion)}
                    fullWidth
                    helperText={touched.declaracion && errors.declaracion}
                    label="Declaración"
                    margin="normal"
                    name="declaracion"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.declaracion}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.fecha_validacion && errors.fecha_validacion)}
                    fullWidth
                    helperText={touched.fecha_validacion && errors.fecha_validacion}
                    label="Fecha Validación"
                    margin="normal"
                    name="fecha_validacion"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.fecha_validacion}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.aduana && errors.aduana)}
                    fullWidth
                    helperText={touched.aduana && errors.aduana}
                    label="Aduana"
                    margin="normal"
                    name="aduana"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.aduana}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.pais_exp && errors.pais_exp)}
                    fullWidth
                    helperText={touched.pais_exp && errors.pais_exp}
                    label="Pais Exp"
                    margin="normal"
                    name="pais_exp"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.pais_exp}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.tipo_despacho && errors.tipo_despacho)}
                    fullWidth
                    helperText={touched.tipo_despacho && errors.tipo_despacho}
                    label="Tipo Despacho"
                    margin="normal"
                    name="tipo_despacho"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.tipo_despacho}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.prm && errors.prm)}
                    fullWidth
                    helperText={touched.prm && errors.prm}
                    label="Prm"
                    margin="normal"
                    name="prm"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.prm}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.fecha && errors.fecha)}
                    fullWidth
                    helperText={touched.fecha && errors.fecha}
                    label="Fecha"
                    margin="normal"
                    name="fecha"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.fecha}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
              <Button color="secondary" variant="contained" onClick={() => regresar()}>
                Regresar
              </Button>
              <Button color="primary" variant="contained" type="submit">
                Modificar
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default FormLiquidacion;
