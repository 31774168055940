import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Chip
} from '@material-ui/core';
import { Edit, Cancel, Download } from '@material-ui/icons'
import getInitials from 'src/utils/getInitials';
import { formatDateEs } from '../../mixins/helpers'

const UsuariosTable = ({ usuarios, editar }) => {
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 5));
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows = limit - Math.min(limit, usuarios.length - page * limit);

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return b[1] - a[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const ChipEstados = ({ estado }) => {
    let texto = estado === 'ACT' ? 'ACTIVO' : 'INACTIVO'
    let color = estado === 'ACT' ? 'primary' : 'secondary'
    return (
      <Chip size="small" label={texto} color={color} />
    )
  }
  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Nombre
                </TableCell>
                <TableCell>
                  Correo
                </TableCell>
                <TableCell>
                  Celular
                </TableCell>
                <TableCell>
                  Usuario
                </TableCell>
                <TableCell>
                  Fecha de creación
                </TableCell>
                <TableCell>
                  Opciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(usuarios, getComparator('asc', ''))
                .slice(page * limit, page * limit + limit)
                .map((m, index) => (
                  <TableRow hover key={m.id} selected={selectedCustomerIds.indexOf(m.id) !== -1}>
                    <TableCell>
                      {m.fullname}
                    </TableCell>
                    <TableCell>
                      {m.correo}
                    </TableCell>
                    <TableCell>
                      {m.cellphone}
                    </TableCell>
                    <TableCell>
                      {m.email}
                    </TableCell>
                    <TableCell>
                      {formatDateEs(m.created_at)}
                    </TableCell>
                    <TableCell>
                      <IconButton color="primary" aria-label="delete" size="small" onClick={() => editar(m)}>
                        <Edit fontSize="inherit" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={usuarios.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

UsuariosTable.propTypes = {
  usuarios: PropTypes.array.isRequired,
  editar: PropTypes.func
};

export default UsuariosTable;
