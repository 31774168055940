import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon, Grid
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import DialogArchivos from 'src/components/archivos/DialogArchivos';
import UsuariosTable from 'src/components/customer/UsuariosTable';
import FormUsuarios from 'src/components/customer/FormUsuarios';
import Swal from 'sweetalert2'
import al from '../mixins/alertas';
import backend from '../api/backend';

const { http, baseURL, getUser } = backend

const Usuarios = () => {
  let s = getUser()
  const [view, setView] = useState("list")
  const [filter, setFilter] = useState("")
  const [usuarios, setUsuarios] = useState([])
  const [usuariosAux, setUsuariosAux] = useState([])
  const [esCrear, setEsCrear] = useState(false)
  const [currentUser, setCurrentUser] = useState({})
  const [userx, setUserx] = useState([])
  const navigate = useNavigate()

  const getUsuarios = () => {
    al.openLoading("Buscando")
    http.get(`api/get_users/1`).then((resp) => {
      al.closeLoading()
      const r = resp.data;
      setUsuarios(r)
      setUsuariosAux(r)
    }).catch((error) => {
      al.closeLoading()
      al.openError("Error al buscar usuarios")
    });
  }

  const filterTable = (val) => {
    let word = val.target.value
    let filtered = []
    if (word !== "") {
      filtered = usuariosAux.filter((x) => x.fullname.toLowerCase().includes(word) || x.cellphone.toLowerCase().includes(word))
    } else {
      filtered = usuariosAux
    }
    setUsuarios(filtered)
  }
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`
    const downloadLink = document.createElement("a")
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }
  const agregar = () => {
    setCurrentUser({
      id: '',
      email: '',
      fullname: '',
      correo: '',
      password: '',
      nit: '',
      company_name: '',
      cellphone: '',
      foto: '',
      tipoUser: '1',
      estado: 'ACT',
    })
    setEsCrear(true)
  }
  const editar = (user) => {
    setCurrentUser(user)
    setEsCrear(true)
  }
  const regresar = (val) => {
    if (val) {
      getUsuarios()
    }
    setEsCrear(false)
  }
  const numero = 1
  useEffect(() => {
    getUsuarios()
    // setUserx(s)
    // if (s.type_user_id === 1) {
    //   getArchivos()
    // } else {
    //   getArchivosCliente(s.id)
    // }
  }, [numero]);
  return (
    <>
      <Helmet><title>Usuarios | Vaslec</title></Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
        <Container maxWidth={false}>
          {
            !esCrear
              ? (
                <>
                  <Box sx={{ mt: 0 }}>
                    <Card>
                      <CardContent>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <Grid item>
                            <TextField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SvgIcon fontSize="small" color="action">
                                      <SearchIcon />
                                    </SvgIcon>
                                  </InputAdornment>
                                )
                              }}
                              placeholder="Buscar Usuario"
                              variant="outlined"
                              size="small"
                              onKeyUp={filterTable}
                            />
                          </Grid>
                          <Grid item>
                            <Button color="primary" variant="contained" size="small" onClick={() => agregar()}>Agregar Usuario</Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                  <UsuariosTable
                    id="ringtone-menu-mercxx"
                    keepMounted
                    usuarios={usuarios}
                    editar={(user) => editar(user)}
                  />
                </>
              )
              : (
                <FormUsuarios usuario={currentUser} regresar={(val) => regresar(val)} />
              )
          }
        </Container>
      </Box>
    </>
  );
}

export default Usuarios
