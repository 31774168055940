import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon, Grid
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import DialogArchivos from 'src/components/archivos/DialogArchivos';
import ArchivosTable from 'src/components/archivos/ArchivosTable';
import Swal from 'sweetalert2'
import al from '../mixins/alertas';
import backend from '../api/backend';

const { http, baseURL, getUser } = backend
const Toolbar = ({ filterTable, actualizar, esCliente }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const descargar = () => {
    setOpenDialog(true);
  }
  const handleCloseMerc = () => {
    setOpenDialog(false);
  };
  return (
    <Box sx={{ mt: 0 }}>
      <Card>
        <CardContent>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Buscar Archivo"
                variant="outlined"
                size="small"
                onKeyUp={filterTable}
              />
            </Grid>
            <Grid item>
              {
              esCliente && <Button color="primary" variant="contained" size="small" onClick={() => descargar()}>Agregar Archivo</Button>
              }
            </Grid>
          </Grid>
          <DialogArchivos
            id="ringtone-menu-merc"
            keepMounted
            open={openDialog}
            onClose={handleCloseMerc}
            actualizar={actualizar}
          />
        </CardContent>
      </Card>
    </Box>
  )
}

const Archivos = () => {
  let s = getUser()
  const [view, setView] = useState("list")
  const [filter, setFilter] = useState("")
  const [archivos, setArchivos] = useState([])
  const [archivosAux, setArchivosAux] = useState([])
  const [userx, setUserx] = useState([])
  const navigate = useNavigate();
  const getArchivos = () => {
    al.openLoading('Buscando ...')
    http.get(`api/get_archivos`).then((resp) => {
      const r = resp.data;
      setArchivos(r)
      setArchivosAux(r)
    }).catch((error) => {
      al.openError(`Error al buscar la lista de archivos ${error}`)
      console.log(error)
    }).finally(() => {
      al.closeLoading()
    });
  }
  const getArchivosCliente = (id) => {
    http.get(`api/get_archivos_cliente/${id}`).then((resp) => {
      const r = resp.data;
      setArchivos(r)
      setArchivosAux(r)
    }).catch((error) => {
      console.log(error)
    })
  }
  const filterTable = (val) => {
    let word = val.target.value
    let filtered = archivosAux.filter((x) => x.nombre.toLowerCase().includes(word) || x.filename.toLowerCase().includes(word) || (!!x.user && x.user.fullname.toLowerCase().includes(word)))
    setArchivos(filtered)
  }
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`
    const downloadLink = document.createElement("a")
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }
  const descargar = (id) => {
    let extention = id.filename.split('.')
    al.openLoading("Descargando ...")
    http.get(`api/show_file_archivos/${id.id}`).then((resp) => {
      const r = resp.data;
      downloadBase64File(extention.pop(), r.file, id.filename)
      al.closeLoading()
    }).catch((error) => {
      al.closeLoading()
      al.openError('error')
    })
  }
  const eliminar = (data) => {
    Swal.fire({
      title: `Desea eliminar el archivo ${data.nombre} ?`,
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Eliminar`,
      cancelButtonText: `Salir`
    }).then((result) => {
      if (result.isDenied) {
        al.openLoading("Eliminando ..")
        http.get(`api/eliminar_archivo/${data.id}`).then((resp) => {
          const r = resp.data;
          al.closeLoading()
          al.openSuccess()
          getArchivos()
        }).catch((error) => {
          al.closeLoading()
          al.openError()
        })
      }
    })
  }
  const numero = 1
  useEffect(() => {
    setUserx(s)
    if (s.type_user_id === 1) {
      getArchivos()
    } else {
      getArchivosCliente(s.id)
    }
  }, [numero]);
  return (
    <>
      <Helmet><title>Archivos</title></Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
        <Container maxWidth={false}>
          <Toolbar filterTable={filterTable} actualizar={() => getArchivos()} esCliente={userx.type_user_id !== 2} />
          <ArchivosTable
            esCliente={userx.type_user_id !== 2}
            archivos={archivos}
            descargar={(id) => descargar(id)}
            eliminar={(data) => eliminar(data)}
          />
        </Container>
      </Box>
    </>
  );
}

export default Archivos
