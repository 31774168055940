import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';
import { useEffect, useState, useRef } from 'react';
import {
  Box, Button, Card, CardContent,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  MenuItem, Autocomplete,
  Snackbar, Alert, Grid, TextField, InputAdornment, SvgIcon, FormControlLabel, Checkbox
} from '@material-ui/core';
import * as XLSX from 'xlsx'
import { Search as SearchIcon } from 'react-feather';
import { format, parse } from 'date-fns';
import backend from '../../api/backend';
import MercaderiaTable from "./MercaderiaTable";
import Mercaderia from "./Mercaderia";
import DataTableDespachos from './DataTableDespachos';
import FormDescripcion from './FormDescripcion';
import FormDespacho from './FormDespacho';
import DataTablaLiquidacion from './DataTablaLiquidacion';
import FormLiquidacion from './FormLiquidacion';
import DownloadExcel from './DownloadExcel';
import { despachos, liquidacionFilter, liquidacionFilterExport } from '../../api/apiStatic';
import al from '../../mixins/alertas';

const { http } = backend

const Toolbar = ({
  buscar,
  laData = [],
  elFiltro, elValor,
  setElFiltro, setElValor, clienteId, byDate,
  setByDate, textoSelectFecha, setTextoSelectFecha,
  fechaInicio, setFechaInicio, fechaFin, setFechaFin
}) => {
  const elSelect = liquidacionFilter
  const elSelectExcel = liquidacionFilterExport
  const [openDialog, setOpenDialog] = useState(false);
  const [valueDialog, setValueDialog] = useState('');
  const newData = JSON.parse(JSON.stringify(laData))
  const elSelectFechas = [
    {
      value: 'fecha_validacion',
      label: 'Fecha Validación',
      check: true,
    },
    {
      value: 'fecha_de_pago',
      label: 'Fecha de Pago',
      check: true,
    },
    {
      value: 'fecha_de_salida_aduana',
      label: 'Fecha salida aduana',
      check: true,
    },
    {
      value: 'fecha_de_embarque',
      label: 'Fecha de embarque',
      check: true,
    },
    {
      value: 'fecha_llegada',
      label: 'Fecha llegada',
      check: true,
    },
    {
      value: 'fecha_retiro_prm',
      label: 'Fecha returo prm',
      check: true,
    },
    {
      value: 'fecha_examen_previo',
      label: 'Fecha examen previo',
      check: true,
    },
    {
      value: 'fecha_envio_de_factura',
      label: 'Fecha envio de factura',
      check: true,
    },
    {
      value: 'fecha_aprobacion_factura',
      label: 'Fecha aprobación factura',
      check: true,
    },
    {
      value: 'fecha_envio_legajo_documentos',
      label: 'Fecha envio legajo documentos',
      check: true,
    }
  ]
  const handleTexto = (event) => {
    setTexto(event.target.value);
  };
  const handleCloseMerc = (newValue) => {
    setOpenDialog(false);
    if (newValue) {
      setValueDialog(newValue);
    }
  };
  const descargar = () => {
    setOpenDialog(true);
  }
  const handleSelect = (event) => {
    setTextoSelect(event.target.value);
  };
  const defaultProps = {
    options: elSelect,
    getOptionLabel: (option) => option.label,
  };
  const flatProps = {
    options: elSelect.map((option) => option.label),
  };
  return (
    <Box sx={{ mt: 0 }}>
      <Card>
        <CardContent>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item sm={2}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={byDate}
                    onChange={(event) => {
                      setByDate(event.target.checked)
                    }}
                    name="checkedB"
                    color="primary"
                  />
                )}
                label="Por Fecha"
              />
            </Grid>
            {
              byDate
                ? (
                  <>
                    <Grid item sm={4}>
                      <Autocomplete
                        options={elSelectFechas}
                        value={textoSelectFecha}
                        onChange={(event, newValue) => {
                          setTextoSelectFecha(newValue);
                        }}
                        id="debug2"
                        renderInput={(params) => <TextField label="Búsqueda" {...params} size="small" />}
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <TextField
                        id="datetime-localx"
                        type="date"
                        fullWidth
                        name="fecha_iniciox"
                        label="Desde"
                        onChange={(event) => {
                          setFechaInicio(event.target.value)
                        }}
                        value={fechaInicio}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <TextField
                        id="datetime-localxx"
                        type="date"
                        fullWidth
                        name="fecha_finx"
                        label="Hasta"
                        onChange={(event) => {
                          setFechaFin(event.target.value)
                        }}
                        value={fechaFin}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item sm={3}>
                      <Autocomplete
                        options={elSelect}
                        value={elFiltro}
                        onChange={(event, newValue) => {
                          setElFiltro(newValue);
                        }}
                        id="debug"
                        getOptionSelected={(option) => option.value.toString()}
                        renderInput={(params) => <TextField {...params} size="small" />}
                      />
                    </Grid>
                    <Grid item sm={5}>
                      {elFiltro !== "todo"
                    && (
                      <TextField
                        variant="outlined"
                        size="small"
                        value={elValor}
                        onChange={setElValor}
                      />
                    )}
                    </Grid>
                  </>
                )
            }
            <Grid item sm={2}>
              <Button color="primary" variant="contained" size="medium" onClick={() => buscar()}>
                Buscar
              </Button>
              <Button color="secondary" variant="contained" size="medium" onClick={() => descargar()}>
                Excel
              </Button>
              <DownloadExcel
                id="ringtone-menu-merc"
                keepMounted
                open={openDialog}
                onClose={handleCloseMerc}
                value={valueDialog}
                datos={elSelectExcel}
                laData={newData}
                elFiltro={elFiltro}
                elValor={elValor}
                clienteId={clienteId}
                servicio="liquidacion"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

const TablaLiquidacion = ({ clienteId, numero, actualizar, soloLectura }) => {
  const ahora = format(new Date(), "yyyy-MM-dd")
  const [mercaderia, setMercaderia] = useState([])
  const [showTable, setShowTable] = useState(true)
  const [currentData, setCurrentData] = useState([])
  const [showNotification, setShowNotificaction] = useState(false);
  const [auxClave, setAuxClave] = useState('')
  const [auxValor, setAuxValor] = useState('')
  const [elFiltro, setElFiltro] = useState('todo')
  const [elValor, setElValor] = useState('todo')
  const [byDate, setByDate] = useState(false)
  const [fechaInicio, setFechaInicio] = useState(ahora)
  const [fechaFin, setFechaFin] = useState(ahora)
  const [textoSelectFecha, setTextoSelectFecha] = useState('')
  const [pagination, setPagination] = useState({
    current_page: 1,
    desde: 0,
    hasta: 0,
    next_page_url: '',
    prev_page_url: '',
    first_page_url: '',
    per_page: '',
    total: 0
  })
  const getMercaderia = (page) => {
    if (byDate && !textoSelectFecha) {
      al.openError('Debe ingresar un criterio de búsqueda')
    } else {
      setMercaderia([])
      al.openLoading('Buscando mercadería ...')
      http.post(`api/get_liquidacion/${clienteId}?page=${page}`, {
        filtro: elFiltro,
        valor: elValor,
        byDate,
        textoSelectFecha,
        fechaInicio,
        fechaFin
      }).then((resp) => {
        const r = resp.data;
        setMercaderia(r.data)
        setPagination({
          current_page: r.current_page,
          desde: r.from,
          hasta: r.to,
          next_page_url: r.next_page_url,
          prev_page_url: r.prev_page_url,
          first_page_url: r.first_page_url,
          per_page: r.per_page,
          total: Math.ceil(r.total / 3)
        })
      }).catch((error) => {
        al.openError(`Error al buscar la mercadería ${error}`)
        console.log(error)
      }).finally(() => {
        al.closeLoading()
      })
    }
  }
  const changeMode = (show, data = {}) => {
    setShowTable(show)
    setCurrentData(data)
    actualizar()
  }
  const handleNotification = (showx) => {
    setShowNotificaction(showx)
  }
  const fechas = ['created_at', 'fecha_dui', 'fecha_fin', 'fecha_inicio', 'fecha_trib']
  useEffect(() => getMercaderia(1), [numero]);
  const buscarTabla = () => {
    getMercaderia(1)
  }
  const buscarDeFiltro = (val) => {
    getMercaderia(val, auxClave, auxValor)
  }
  const handleSelect = (event) => {
    setElValor(event.target.value);
  }
  const handleFiltro = (event) => {
    setElFiltro(event.value)
  }
  const handleByDate = (event) => {
    console.log(event)
    setByDate(event)
  }
  const handleFechaInicio = (event) => {
    setFechaInicio(event)
  }
  const handleFechaFin = (event) => {
    setFechaFin(event)
  }
  const handleTextoSelectFecha = (event) => {
    setTextoSelectFecha(event.value)
  }
  const eliminarUno = (id) => {
    al.openLoading("Eliminando")
    http.get(`api/eliminar_liquidacion/${id}`).then((resp) => {
      al.closeLoading()
      al.openSuccess("Eliminación exitosa")
      getMercaderia(1)
    }).catch((error) => {
      al.closeLoading()
      al.openError("Error al eliminar intente de nuevo")
    })
  }
  const eliminarRelacionados = (id) => {
    al.openLoading("Eliminando")
    http.get(`api/eliminar_liquidacion_registros/${id}`).then((resp) => {
      al.closeLoading()
      al.openSuccess("Eliminación exitosa")
      getMercaderia(1)
    }).catch((error) => {
      al.closeLoading()
      al.openError("Error al eliminar intente de nuevo")
    })
  }
  const eliminar = (mer) => {
    console.log(mer)
    Swal.fire({
      title: 'Eliminar registros?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Eliminar registro actual',
      denyButtonText: `Eliminar multiple (Subida)`,
      cancelButtonText: 'Salir',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        eliminarUno(mer.id)
      } else if (result.isDenied) {
        eliminarRelacionados(mer.registro_id)
      }
    })
  }
  return (
    <>
      <Helmet><title>Productos</title></Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        { showTable ? (
          <Container maxWidth={false}>
            <Toolbar
              buscar={() => buscarTabla()}
              laData={mercaderia}
              elFiltro={elFiltro}
              elValor={elValor}
              setElFiltro={(val) => handleFiltro(val)}
              setElValor={(val) => handleSelect(val)}
              clienteId={clienteId}
              byDate={byDate}
              setByDate={(val) => handleByDate(val)}
              textoSelectFecha={textoSelectFecha}
              setTextoSelectFecha={(val) => handleTextoSelectFecha(val)}
              fechaInicio={fechaInicio}
              setFechaInicio={(val) => handleFechaInicio(val)}
              fechaFin={fechaFin}
              setFechaFin={(val) => handleFechaFin(val)}
            />
            <Box sx={{ pt: 1 }}>
              <Card>
                <CardContent>
                  <Box>
                    <DataTablaLiquidacion
                      mercaderia={mercaderia}
                      changeMode={(data) => changeMode(false, data)}
                      soloLectura={soloLectura}
                      pagination={pagination}
                      setPagex={(p) => buscarDeFiltro(p)}
                      eliminar={(da) => eliminar(da)}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Container>
        ) : (
          <Container maxWidth={false}>
            <FormLiquidacion data={currentData} regresar={() => changeMode(true, {})} />
          </Container>
        )}
        <Snackbar open={showNotification} autoHideDuration={6000} onClose={() => handleNotification(false)}>
          <Alert variant="filled" onClose={() => handleNotification(false)} severity="success">
            Operación Exitosa
          </Alert>
        </Snackbar>
      </Box>
    </>
  )
}

export default TablaLiquidacion;
