import { Helmet } from 'react-helmet';
import {
  Box,
  Container, Link,
  Typography
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const NotFound = () => (
  <>
    <Helmet>
      <title>Pagina no encontrada</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center'
      }}
    >
      <Container maxWidth="md">
        <Typography
          align="center"
          color="textPrimary"
          variant="h1"
        >
          404: Lo que estas buscando no esta aquí
        </Typography>
        <Typography
          align="center"
          color="textPrimary"
          variant="subtitle2"
        >
          No deberías ingresar directamente por la url, debes hacerlo por el Login -
          <Typography
            color="textSecondary"
            variant="body1"
          >
            <Link
              component={RouterLink}
              to="/login"
              variant="h6"
            >
              Ingresar al Sistema
            </Link>
          </Typography>
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
          <img
            alt="Under development"
            src="/static/images/undraw_page_not_found_su7k.svg"
            style={{
              marginTop: 50,
              display: 'inline-block',
              maxWidth: '100%',
              width: 560
            }}
          />
        </Box>
      </Container>
    </Box>
  </>
);

export default NotFound;
