import { Helmet } from 'react-helmet';
import { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2'
import {
  Box, Button, Card, CardContent,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography, Grid, Autocomplete, TextField
} from '@material-ui/core';
import backend from '../../api/backend';
import al from '../../mixins/alertas';
import { mercaderiaFilter, mercaderiaExport } from '../../api/apiStatic'
import DataTableMercaderia from './DataTableMercaderia';
import FormDescripcion from './FormDescripcion';
import DownloadExcel from './DownloadExcel';

const Toolbar = ({ buscar, laData, elFiltro, elValor, setElFiltro, setElValor, clienteId }) => {
  const elSelect = mercaderiaFilter
  const elSelectExport = mercaderiaExport
  const [openDialog, setOpenDialog] = useState(false);
  const [valueDialog, setValueDialog] = useState('Dione');
  const newData = JSON.parse(JSON.stringify(laData))
  const handleCloseMerc = (newValue) => {
    setOpenDialog(false);
    if (newValue) {
      setValueDialog(newValue);
    }
  };
  const descargar = () => {
    setOpenDialog(true);
  }
  return (
    <Box sx={{ mt: 0 }}>
      <Card>
        <CardContent>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item sm={3}>
              <Autocomplete
                options={elSelect}
                value={elFiltro}
                onChange={(event, newValue) => {
                  setElFiltro(newValue);
                }}
                id="debug"
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Grid>
            <Grid item sm={9}>
              {elFiltro !== "todo"
              && (
                <TextField
                  variant="outlined"
                  size="small"
                  value={elValor}
                  onChange={setElValor}
                />
              )}
              <Button color="primary" variant="contained" size="medium" onClick={() => buscar()}>
                Buscar
              </Button>
              <Button color="secondary" variant="contained" size="medium" onClick={() => descargar()}>
                Excel
              </Button>
              <DownloadExcel
                id="ringtone-menu-merc"
                keepMounted
                open={openDialog}
                onClose={handleCloseMerc}
                value={valueDialog}
                datos={elSelectExport}
                laData={newData}
                elFiltro={elFiltro}
                elValor={elValor}
                clienteId={clienteId}
                servicio="mercaderia"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

const TablaMercaderias = ({ clienteId, numero, actualizar, soloLectura }) => {
  const { http } = backend
  const [mercaderia, setMercaderia] = useState([])
  const [auxmercaderia, setAuxMercaderia] = useState([])
  const [showTable, setShowTable] = useState(true)
  const [currentData, setCurrentData] = useState([])
  const [elFiltro, setElFiltro] = useState('todo')
  const [elValor, setElValor] = useState('todo')
  const [pagination, setPagination] = useState({
    current_page: 1,
    desde: 0,
    hasta: 0,
    next_page_url: '',
    prev_page_url: '',
    first_page_url: '',
    per_page: '',
    total: 0
  })
  const handleClickOpen = () => {
    setOpen(true);
    setStatus('loading')
    setMessage('Subiendo ...')
  };
  const setResponse = (tipo, mensaje) => {
    let x1 = 'error'
    let timeout = 2000
    if (tipo) {
      x1 = 'success'
      timeout = 1500
    }
    setStatus(x1)
    setMessage(mensaje)
    setTimeout(() => {
      setOpen(false)
    }, timeout)
  }
  const handleClose = () => {
    setOpen(false);
  };
  const getMercaderia = (page) => {
    setMercaderia([])
    al.openLoading('Buscando ...')
    http.post(`api/get_descripcion_minima/${clienteId}?page=${page}`, {
      filtro: elFiltro,
      valor: elValor
    }).then((resp) => {
      const r = resp.data;
      setMercaderia(r.data)
      setPagination({
        current_page: r.current_page,
        desde: r.from,
        hasta: r.to,
        next_page_url: r.next_page_url,
        prev_page_url: r.prev_page_url,
        first_page_url: r.first_page_url,
        per_page: r.per_page,
        total: Math.ceil(r.total / 5)
      })
    }).catch((error) => {
      al.openError(`Error al cargar los datos ${error}`)
      console.log(error)
    }).finally(() => {
      al.closeLoading()
    })
  }
  const changeMode = (show, data = {}) => {
    setShowTable(show)
    setCurrentData(data)
    actualizar()
  }
  useEffect(() => getMercaderia(1), [numero]);
  const buscarTabla = () => {
    getMercaderia(1)
  }
  const buscarDeFiltro = (val) => {
    getMercaderia(val)
  }
  const handleSelect = (event) => {
    setElValor(event.target.value);
  }
  const handleFiltro = (event) => {
    if (!!event) {
      setElFiltro(event.value)
    }
  }
  const eliminarUno = (id) => {
    al.openLoading("Eliminando")
    http.get(`api/eliminar_descripcion/${id}`).then((resp) => {
      al.closeLoading()
      al.openSuccess("Eliminación exitosa")
      getMercaderia(1)
    }).catch((error) => {
      al.closeLoading()
      al.openError("Error al eliminar intente de nuevo")
    })
  }
  const eliminarRelacionados = (id) => {
    al.openLoading("Eliminando")
    http.get(`api/eliminar_descripcion_registros/${id}`).then((resp) => {
      al.closeLoading()
      al.openSuccess("Eliminación exitosa")
      getMercaderia(1)
    }).catch((error) => {
      al.closeLoading()
      al.openError("Error al eliminar intente de nuevo")
    })
  }
  const eliminar = (mer) => {
    console.log(mer)
    Swal.fire({
      title: 'Eliminar registros?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Eliminar registro actual',
      denyButtonText: `Eliminar multiple (Subida)`,
      cancelButtonText: 'Salir',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        eliminarUno(mer.id)
      } else if (result.isDenied) {
        eliminarRelacionados(mer.registro_id)
      }
    })
  }
  return (
    <>
      <Helmet><title>Productos</title></Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        { showTable ? (
          <Container maxWidth={false}>
            <Toolbar
              buscar={(data, data2) => buscarTabla(data, data2)}
              laData={mercaderia}
              elFiltro={elFiltro}
              elValor={elValor}
              setElFiltro={(val) => handleFiltro(val)}
              setElValor={(val) => handleSelect(val)}
              clienteId={clienteId}
            />
            <Box sx={{ pt: 1 }}>
              <Card>
                <CardContent>
                  <Box>
                    <DataTableMercaderia
                      mercaderia={mercaderia}
                      changeMode={(data) => changeMode(false, data)}
                      soloLectura={soloLectura}
                      pagination={pagination}
                      setPagex={(p) => buscarDeFiltro(p)}
                      eliminar={(p) => eliminar(p)}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Container>
        ) : (
          <Container maxWidth={false}>
            <FormDescripcion data={currentData} regresar={() => changeMode(true, {})} />
          </Container>
        )}
      </Box>
    </>
  )
}

export default TablaMercaderias;
