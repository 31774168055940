import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';
import { useEffect, useState, memo } from 'react';
import { Alert, Autocomplete, Box, Button, Card, CardContent, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, Snackbar, TextField } from '@material-ui/core';
import { format, parse } from 'date-fns';
import moment from 'moment'
import backend from '../../api/backend';
import DataTableDespachos from './DataTableDespachos';
import FormDespacho from './FormDespacho';
import excel from '../../utils/excel';
import { despachos } from '../../api/apiStatic';
import al from '../../mixins/alertas';

const DescargarExcel = (props) => {
  const { http } = backend
  const { onClose, value: valueProp, open, datos, laData, textoSelect, texto, textoSelectFecha, clienteId, byDate, fechaInicio, fechaFin, ...other } = props;
  const filtrosx = datos.concat(despachos.selectFecha)
  const [losDatos, setLosDatos] = useState(filtrosx)
  const [todo, setTodo] = useState(false)
  const handleChange = (event) => {
    const newList = losDatos.map((item) => {
      if (item.label === event.target.name) {
        return { ...item, check: event.target.checked }
      }
      return item
    })
    setLosDatos(newList);
  };
  const handleChangeChecked = (event) => {
    setTodo(event.target.checked)
    const newList = losDatos.map((item) => ({ ...item, check: event.target.checked }))
    setLosDatos(newList);
  };
  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    let { value } = textoSelect
    let { value: valuefecha } = textoSelectFecha
    http.post(`api/get_mercaderia_all/${clienteId}`, {
      filtro: byDate ? valuefecha : value,
      valor: texto,
      esFecha: byDate,
      inicio: fechaInicio,
      fin: fechaFin
    }).then((resp) => {
      const r = resp.data;
      const filteredColumns = losDatos.map((a) => (a.check ? 'false' : a.value))
      filteredColumns.push('id', 'registro_id', 'created_at', 'updated_at', 'cliente_id', 'user_id', 'observaciones')
      r.forEach((x) => {
        filteredColumns.forEach((y) => {
          if (y !== 'false') {
            // eslint-disable-next-line no-param-reassign
            delete x[y]
          }
        })
      })
      excel.objectToExcel(r, "Mercaderia", "mercaderia")
      onClose();
    }).catch((error) => {
      console.log(error)
    })
    // const filteredColumns = losDatos.map((a) => (a.check ? 'false' : a.value))
    // laData.forEach((x) => {
    //   filteredColumns.forEach((y) => {
    //     if (y !== 'false') {
    //       // eslint-disable-next-line no-param-reassign
    //       delete x[y]
    //     }
    //   })
    // })
    // excel.objectToExcel(laData, "Algo", "tramites")
    // onClose();
  };

  return (
    <Dialog
      id="dialogxxxx"
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">Seleccione columnas a exportar</DialogTitle>
      <DialogContent dividers>
        <FormGroup>
          <Checkbox checked={todo} onChange={handleChangeChecked} name="Seleccionar Todo" />
          {losDatos.map((m, index) => (
            <FormControlLabel
              key={m.id}
              control={<Checkbox checked={m.check} onChange={handleChange} name={m.label} />}
              label={m.label}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Salir
        </Button>
        <Button onClick={handleOk} color="primary">
          Generar Excel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const TablaDespachos = ({ clienteId, numero, actualizar, soloLectura }) => {
  const ahora = format(new Date(), "yyyy-MM-dd")
  const { http } = backend
  const [mercaderia, setMercaderia] = useState([])
  const [auxmercaderia, setAuxMercaderia] = useState([])
  const [showTable, setShowTable] = useState(true)
  const [currentData, setCurrentData] = useState([])
  const [showNotification, setShowNotificaction] = useState(false);
  //toolbar===========================================
  const elSelect = despachos.select
  const elSelectFechas = despachos.selectFecha
  const elSelectExcel = despachos.selectExport
  const [texto, setTexto] = useState("")
  const [textoSelect, setTextoSelect] = useState({
    value: 'todo',
    label: 'Todo',
    check: true
  })
  const [textoSelectFecha, setTextoSelectFecha] = useState("")
  const [fechaInicio, setFechaInicio] = useState(ahora)
  const [fechaFin, setFechaFin] = useState(ahora)
  const [openDialog, setOpenDialog] = useState(false);
  const [valueDialog, setValueDialog] = useState('Dione');
  const [byDate, setByDate] = useState(false)
  const [pagination, setPagination] = useState({
    current_page: 1,
    desde: 0,
    hasta: 0,
    next_page_url: '',
    prev_page_url: '',
    first_page_url: '',
    per_page: '',
    total: 0
  })
  // const newData = JSON.parse(JSON.stringify(laData))
  const handleClose = (newValue) => {
    setOpenDialog(false);
    if (newValue) {
      setValueDialog(newValue);
    }
  };
  const descargar = () => {
    setOpenDialog(true);
  }
  //toolbar===========================================
  const getMercaderia = (page) => {
    let { value } = textoSelect
    let { value: valuefecha } = textoSelectFecha
    setMercaderia([])
    al.openLoading('Buscando mercadería')
    http.post(`api/get_mercaderia/${clienteId}?page=${page}`, {
      filtro: byDate ? valuefecha : value,
      valor: texto,
      esFecha: byDate,
      inicio: fechaInicio,
      fin: fechaFin
    }).then((resp) => {
      const r = resp.data;
      setMercaderia(r.data)
      setPagination({
        current_page: r.current_page,
        desde: r.from,
        hasta: r.to,
        next_page_url: r.next_page_url,
        prev_page_url: r.prev_page_url,
        first_page_url: r.first_page_url,
        per_page: r.per_page,
        total: Math.ceil(r.total / 4)
      })
    }).catch((error) => {
      al.openError('Error al buscar la mercadería')
      console.log(error)
    }).finally(() => {
      al.closeLoading()
    })
  }
  const changeMode = (show, data = {}) => {
    setShowTable(show)
    setCurrentData(data)
    actualizar()
  }
  const handleNotification = (showx) => {
    setShowNotificaction(showx)
  }
  const fechas = ['created_at', 'fecha_dui', 'fecha_fin', 'fecha_inicio', 'fecha_trib']
  useEffect(() => getMercaderia(1), [numero]);
  const buscarTabla = () => {
    getMercaderia(1)
  }
  const buscarDeFiltro = (val) => {
    getMercaderia(val)
  }
  const eliminarUno = (id) => {
    al.openLoading("Eliminando")
    http.get(`api/eliminar_mercaderia/${id}`).then((resp) => {
      al.closeLoading()
      al.openSuccess("Eliminación exitosa")
      getMercaderia(1)
    }).catch((error) => {
      al.closeLoading()
      al.openError("Error al eliminar intente de nuevo")
    })
  }
  const eliminarRelacionados = (id) => {
    al.openLoading("Eliminando")
    http.get(`api/eliminar_mercaderia_registros/${id}`).then((resp) => {
      al.closeLoading()
      al.openSuccess("Eliminación exitosa")
      getMercaderia(1)
    }).catch((error) => {
      al.closeLoading()
      al.openError("Error al eliminar intente de nuevo")
    })
  }
  const eliminar = (mer) => {
    console.log(mer)
    Swal.fire({
      title: 'Eliminar registros?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Eliminar registro actual',
      denyButtonText: `Eliminar multiple (Subida)`,
      cancelButtonText: 'Salir',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        eliminarUno(mer.id)
      } else if (result.isDenied) {
        eliminarRelacionados(mer.registro_id)
      }
    })
  }
  return (
    <>
      <Helmet><title>Vaslec | Despachos</title></Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        { showTable ? (
          <Container maxWidth={false}>
            {/*<Toolbar buscar={(data, data2) => buscarTabla(data, data2)} laData={mercaderia} />*/}
            <Box sx={{ mt: 0 }}>
              <Card>
                <CardContent>
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid item sm={2}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={byDate}
                            onChange={(event) => {
                              setByDate(event.target.checked)
                            }}
                            name="checkedB"
                            color="primary"
                          />
                        )}
                        label="Buscar por Fechas"
                      />
                    </Grid>
                    {
                      !byDate
                        ? (
                          <>
                            <Grid item sm={4}>
                              <Autocomplete
                                options={elSelect}
                                value={textoSelect}
                                onChange={(event, newValue) => {
                                  setTextoSelect(newValue);
                                }}
                                id="debug"
                                renderInput={(params) => <TextField label="Campo de búsqueda" {...params} size="small" />}
                              />
                            </Grid>
                            <Grid item sm={4}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                label="Texto a buscar"
                                size="small"
                                value={texto}
                                onChange={(event) => {
                                  setTexto(event.target.value);
                                }}
                              />
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item sm={2}>
                              <Autocomplete
                                options={elSelectFechas}
                                value={textoSelectFecha}
                                onChange={(event, newValue) => {
                                  setTextoSelectFecha(newValue);
                                }}
                                id="debug2"
                                renderInput={(params) => <TextField label="Búsqueda por fecha" {...params} size="small" />}
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <TextField
                                id="datetime-localx"
                                type="date"
                                fullWidth
                                name="fecha_iniciox"
                                label="Desde"
                                onChange={(event) => {
                                  setFechaInicio(event.target.value)
                                }}
                                value={fechaInicio}
                                variant="outlined"
                                size="small"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <TextField
                                id="datetime-localxx"
                                type="date"
                                fullWidth
                                name="fecha_finx"
                                label="Hasta"
                                onChange={(event) => {
                                  setFechaFin(event.target.value)
                                }}
                                value={fechaFin}
                                variant="outlined"
                                size="small"
                              />
                            </Grid>
                          </>
                        )
                    }
                    <Grid item sm={2}>
                      <Button
                        color="primary"
                        variant="contained"
                        size="medium"
                        onClick={() => buscarTabla()}
                      >
                        Buscar
                      </Button>
                      <Button color="secondary" variant="contained" size="medium" onClick={() => descargar()}>
                        Excel
                      </Button>
                      <DescargarExcel
                        id="ringtone-menu"
                        keepMounted
                        open={openDialog}
                        onClose={handleClose}
                        value={valueDialog}
                        datos={elSelectExcel}
                        laData={mercaderia}
                        textoSelect={textoSelect}
                        textoSelectFecha={textoSelectFecha}
                        texto={texto}
                        clienteId={clienteId}
                        byDate={byDate}
                        fechaInicio={fechaInicio}
                        fechaFin={fechaFin}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
            <Box sx={{ pt: 1 }}>
              <Card>
                <CardContent>
                  <Box>
                    <DataTableDespachos
                      mercaderia={mercaderia}
                      changeMode={(data) => changeMode(false, data)}
                      soloLectura={soloLectura}
                      pagination={pagination}
                      setPagex={(p) => buscarDeFiltro(p)}
                      actualizar={() => actualizar()}
                      eliminar={(da) => eliminar(da)}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Container>
        ) : (
          <Container maxWidth={false}>
            <FormDespacho data={currentData} regresar={() => changeMode(true, {})} />
          </Container>
        )}
        {/*<Snackbar open={showNotification} autoHideDuration={6000} onClose={() => handleNotification(false)}>*/}
        {/*  <Alert variant="filled" onClose={() => handleNotification(false)} severity="success">*/}
        {/*    Operación Exitosa*/}
        {/*  </Alert>*/}
        {/*</Snackbar>*/}
      </Box>
    </>
  )
}

export default TablaDespachos;
