import axios from 'axios';

const baseURL = process.env.NODE_ENV !== 'production' ? 'http://127.0.0.1:8000/' : 'https://backend.vaslec.com/'

const http = axios.create({
  baseURL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
});

const theJwt = localStorage.getItem('jx')
if (!!theJwt) {
  http.defaults.headers.common.Authorization = `Bearer ${theJwt}`
}

export default {
  baseURL,
  http,
  setLocalJwt(jwt) {
    localStorage.setItem('jx', jwt)
    http.defaults.headers.common.Authorization = `Bearer ${jwt}`
  },
  setMenu(menu) {
    let enc = btoa(JSON.stringify(menu))
    localStorage.setItem('mx', enc)
  },
  getMenu() {
    let dec = atob(localStorage.getItem('mx'))
    return JSON.parse(dec)
  },
  setUser(user) {
    let encx = btoa(JSON.stringify(user))
    localStorage.setItem('sx', encx)
  },
  getUser() {
    let code = !localStorage.getItem('sx') ? null : localStorage.getItem('sx')
    if (code === null) {
      return ""
    }
    let decx = atob(code)
    return JSON.parse(decx)
  },
  cleanJwt() {
    localStorage.clear()
  }
};
