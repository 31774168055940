import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton, Button, Pagination
} from '@material-ui/core';
import { Edit, FileUpload, FileDownload, Delete } from '@material-ui/icons'
import getInitials from 'src/utils/getInitials';
import Swal from 'sweetalert2';
import { formatDateEs } from '../../mixins/helpers'
import backend from '../../api/backend';
import al from '../../mixins/alertas';

const DataTableDespachos = ({ mercaderia, changeMode, soloLectura, pagination, setPagex, actualizar, eliminar }) => {
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const { http, baseURL } = backend
  const handlePageChange = (event, newPage) => {
    setPagex(newPage);
  }
  const uploadDespacho = async (ide) => {
    const { value: file } = await Swal.fire({
      title: 'Seleccione el archivo a subir',
      text: 'seleccione un archivo',
      input: 'file',
      confirmButtonText: 'Subir Archivo',
      inputAttributes: {
        'aria-label': 'Subir el archivo'
      }
    })
    if (file) {
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      let formData = new FormData()
      formData.append('file', file)
      al.openLoading("Subiendo")
      http.post(`api/upload_archivo_despachos/${ide}`, formData, config).then((resp) => {
        const r = resp.data;
        al.closeLoading()
        al.openSuccess()
        actualizar()
      }).catch((error) => {
        al.closeLoading()
        al.openError()
      })
    }
  }
  const downloadDespacho = (mer) => {
    const link = document.createElement('a');
    link.href = `${baseURL}/api/descargar_despacho/${mer.id}`;
    link.target = '_blank'
    link.click();
  }
  return (
    <Card>
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableBody>
              {mercaderia.map((m, index) => (
                <TableRow hover key={m.id} selected={selectedCustomerIds.indexOf(m.id) !== -1}>
                  <TableCell style={{ width: '20%' }}>
                    <small>
                      <strong>Número </strong>
                      :
                      {m.numero_tramite}
                      <br />
                      <strong>Ref. Interna </strong>
                      :
                      {m.ref_interna}
                      <br />
                      <strong>Fecha Inicio </strong>
                      :
                      { !!m.fecha_inicio && formatDateEs(m.fecha_inicio) }
                      <br />
                      <strong>Fecha Fin </strong>
                      :
                      { !!m.fecha_fin && formatDateEs(m.fecha_fin) }
                    </small>
                  </TableCell>
                  <TableCell style={{ width: '20%' }}>
                    <small>
                      <strong>Cliente </strong>
                      :
                      {m.cliente}
                      <br />
                      <strong>Detalle Merc. </strong>
                      :
                      {m.detalle_mercaderia}
                      <br />
                      <strong>Liquidador </strong>
                      :
                      { m.liquidador }
                      <br />
                      <strong>Gestor </strong>
                      :
                      { m.gestor }
                    </small>
                  </TableCell>
                  <TableCell style={{ width: '20%' }}>
                    <small>
                      <strong>Nro C </strong>
                      :
                      {m.nro_c}
                      <br />
                      <strong>Fecha Dui </strong>
                      :
                      { !!m.fecha_dui && formatDateEs(m.fecha_dui) }
                      <br />
                      <strong>Fecha Trib </strong>
                      :
                      { !!m.fecha_trib && formatDateEs(m.fecha_trib) }
                      <br />
                      <strong>Nro Carpeta </strong>
                      :
                      { m.nro_carpeta }
                    </small>
                  </TableCell>
                  <TableCell style={{ width: '20%' }}>
                    <small>
                      <strong>Patrón Declaración </strong>
                      :
                      {m.patron_declaracion}
                      <br />
                      <strong>Aduana </strong>
                      :
                      { m.aduana }
                      <br />
                      <strong>Canal </strong>
                      :
                      { m.canal }
                      <br />
                      <strong>Recepción Decl. </strong>
                      :
                      { m.recepcion_declaracion }
                    </small>
                  </TableCell>
                  <TableCell style={{ width: '20%' }}>
                    <small>
                      <strong>Presentación Carpeta </strong>
                      :
                      {m.presentacion_carpeta}
                      <br />
                      <strong>Asignación Vista </strong>
                      :
                      { m.asignacion_vista }
                      <br />
                      <strong>Aforo Imp. </strong>
                      :
                      { m.aforo_importacion }
                      <br />
                      <strong>Diligencia </strong>
                      :
                      { m.diligencia }
                    </small>
                  </TableCell>
                  {
                    soloLectura
                      ? (
                        <>
                          <TableCell>
                            <IconButton color="primary" aria-label="delete" size="small" onClick={() => changeMode(m)}>
                              <Edit fontSize="inherit" />
                            </IconButton>
                            <IconButton color="secondary" aria-label="subir" size="small" onClick={() => uploadDespacho(m.id)}>
                              <FileUpload fontSize="inherit" />
                            </IconButton>
                            {
                              !!m.multimedia
                              && (
                                <IconButton color="primary" aria-label="subir" size="small" onClick={() => downloadDespacho(m)}>
                                  <FileDownload fontSize="inherit" />
                                </IconButton>
                              )
                            }
                          </TableCell>
                          <TableCell>
                            <IconButton color="secondary" aria-label="delete" size="small" onClick={() => eliminar(m)}>
                              <Delete fontSize="inherit" />
                            </IconButton>
                          </TableCell>
                        </>
                      ) : ("")
                  }
                </TableRow>
              ))}
              {mercaderia.length > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <div style={{ textAlign: 'center' }}>
        <Pagination color="primary" count={pagination.total} page={pagination.current_page} onChange={handlePageChange} />
      </div>
    </Card>
  );
};

DataTableDespachos.propTypes = {
  mercaderia: PropTypes.array.isRequired,
  changeMode: PropTypes.func
};

export default DataTableDespachos;
