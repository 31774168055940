import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton, Pagination
} from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons'
import getInitials from 'src/utils/getInitials';
import { formatDateEs } from '../../mixins/helpers'
import backend from '../../api/backend';

const DataTableMercaderia = ({ mercaderia, changeMode, soloLectura, pagination, setPagex, eliminar }) => {
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const { http } = backend
  const handlePageChange = (event, newPage) => {
    setPagex(newPage);
  };
  return (
    <Card>
      <PerfectScrollbar>
        <Box>
          <Table size="small">
            <TableBody>
              {mercaderia.map((m, index) => (
                <TableRow hover key={m.id} selected={selectedCustomerIds.indexOf(m.id) !== -1}>
                  <TableCell>
                    <small>
                      <strong>Item </strong>
                      :
                      {m.item}
                      <br />
                      <strong>H.s.c </strong>
                      :
                      {m.hsc}
                      <br />
                      <strong>Descripciones </strong>
                      :
                      {m.descripcion}
                      <br />
                      <strong>Marca </strong>
                      :
                      {m.marca}
                    </small>
                  </TableCell>
                  <TableCell>
                    <small>
                      <strong>Tipo </strong>
                      :
                      {m.tipo}
                      <br />
                      <strong>Clase </strong>
                      :
                      {m.clase}
                      <br />
                      <strong>Modelo </strong>
                      :
                      {m.modelo}
                      <br />
                      <strong>Cuantitativo 1 </strong>
                      :
                      {m.cuantitativo1}
                    </small>
                  </TableCell>
                  <TableCell>
                    <small>
                      <strong>Cuantitativo 2 </strong>
                      :
                      {m.cuantitativo2}
                      <br />
                      <strong>Forma </strong>
                      :
                      {m.forma_presentacion}
                      <br />
                      <strong>Naturaleza </strong>
                      :
                      {m.naturaleza}
                      <br />
                      <strong>Uso / Apl. </strong>
                      :
                      {m.uso_aplicacion}
                    </small>
                  </TableCell>
                  <TableCell>
                    <small>
                      <strong>Otras Car.</strong>
                      :
                      {m.otras_caracteristicas}
                      <br />
                      <strong>Pais </strong>
                      :
                      {m.pais_origen}
                    </small>
                  </TableCell>
                  {
                    soloLectura
                      ? (
                        <>
                          <TableCell>
                            <IconButton color="primary" aria-label="delete" size="small" onClick={() => changeMode(m)}>
                              <Edit fontSize="inherit" />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <IconButton color="secondary" aria-label="delete" size="small" onClick={() => eliminar(m)}>
                              <Delete fontSize="inherit" />
                            </IconButton>
                          </TableCell>
                        </>
                      ) : ("")
                  }
                </TableRow>
              ))}
              {mercaderia.length > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <div style={{ textAlign: 'center' }}>
        <Pagination color="primary" count={pagination.total} page={pagination.current_page} onChange={handlePageChange} />
      </div>
    </Card>
  );
};

DataTableMercaderia.propTypes = {
  mercaderia: PropTypes.array.isRequired,
  changeMode: PropTypes.func
};

export default DataTableMercaderia;
