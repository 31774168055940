import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import { useState } from 'react';
import backend from '../api/backend';

const {
  http, setLocalJwt, setMenu, setUser
} = backend

const Login = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [message, setMessage] = useState("")

  const loginUser = (values) => {
    console.log(values)
    setStatus(true)
    setErrorMessage(false)
    http.post('api/login', values).then((resp) => {
      const r = resp.data;
      console.log(r)
      if (!!r.access_token) {
        setLocalJwt(r.access_token)
        setMenu(r.menu)
        setUser(r.user)
        navigate('/app/dashboard', { replace: true })
      } else {
        setErrorMessage(true)
        setMessage(r.message)
      }
    }).catch((error) => {
      setErrorMessage(true)
      setMessage("Contraseña o Usuario Incorrecto")
    }).finally(() => {
      setStatus(false)
    });
  }

  return (
    <>
      <Helmet>
        <title>Vaslec Internacional</title>
      </Helmet>
      <Box
        sx={{
          // backgroundColor: 'background.default',
          backgroundColor: '#ffffff',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().max(255).required('El usuario es requerido'),
              password: Yup.string().max(255).required('Password es requerido')
            })}
            onSubmit={(values) => {
              loginUser(values)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Vaslec Internacional
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Ingrese su usuario y contraseña
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Usuario"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Contraseña"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={status}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    { status ? 'Ingresando ...' : 'Ingresar' }
                  </Button>
                </Box>
                {/*<Typography*/}
                {/*  color="textSecondary"*/}
                {/*  variant="body1"*/}
                {/*>*/}
                {/*  No tiene una cuenta?*/}
                {/*  {' '}*/}
                {/*  <Link*/}
                {/*    component={RouterLink}*/}
                {/*    to="/register"*/}
                {/*    variant="h6"*/}
                {/*  >*/}
                {/*    Registrarse*/}
                {/*  </Link>*/}
                {/*</Typography>*/}
                { errorMessage ? <Alert severity="error">{message}</Alert> : ""}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
