import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import Budget from 'src/components/dashboard//Budget';
import LatestOrders from 'src/components/dashboard//LatestOrders';
import LatestProducts from 'src/components/dashboard//LatestProducts';
import Sales from 'src/components/dashboard//Sales';
import TasksProgress from 'src/components/dashboard//TasksProgress';
import TotalCustomers from 'src/components/dashboard//TotalCustomers';
import TotalProfit from 'src/components/dashboard//TotalProfit';
import TrafficByDevice from 'src/components/dashboard//TrafficByDevice';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import backend from '../api/backend';

const Dashboard = () => {
  const { http, getUser } = backend
  const [lista, setLista] = useState([])
  const navigate = useNavigate();
  const getData = () => {
    http.get(`api/estadisticas`).then((resp) => {
      const r = resp.data;
      setLista(r)
    }).catch((error) => {
      // navigate('/app/dashboard', { replace: true })
    }).finally(() => {
    });
  }
  let numero = 1
  useEffect(() => {
    let us = getUser()
    if (us.type_user_id === 2) {
      navigate('/app/productos', { replace: true })
    }
    getData()
  }, [numero])
  return (
    <>
      <Helmet>
        <title>Sistema Vaslec</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalCustomers datos={lista.clientes} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <Budget datos={lista.usuarios} />
            </Grid>
            {/*<Grid item lg={3} sm={6} xl={3} xs={12}>*/}
            {/*  <TasksProgress />*/}
            {/*</Grid>*/}
            {/*<Grid item lg={3} sm={6} xl={3} xs={12}>*/}
            {/*  <TotalProfit sx={{ height: '100%' }} />*/}
            {/*</Grid>*/}
            {/*<Grid item lg={8} md={12} xl={9} xs={12}>*/}
            {/*  <Sales />*/}
            {/*</Grid>*/}
            {/*<Grid item lg={4} md={6} xl={3} xs={12}>*/}
            {/*  <TrafficByDevice sx={{ height: '100%' }} />*/}
            {/*</Grid>*/}
            {/*<Grid item lg={4} md={6} xl={3} xs={12}>*/}
            {/*  <LatestProducts sx={{ height: '100%' }} />*/}
            {/*</Grid>*/}
            {/*<Grid item lg={8} md={12} xl={9} xs={12}>*/}
            {/*  <LatestOrders />*/}
            {/*</Grid>*/}
          </Grid>
        </Container>
      </Box>
    </>
  )
};

export default Dashboard;
