import { useState } from 'react';
import { Formik } from 'formik';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid, Link,
  TextField, Typography
} from '@material-ui/core';
import * as Yup from 'yup';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { alpha } from '@material-ui/core/styles';
import backend from '../../api/backend';

const states = [
  {
    value: 'alabama',
    label: 'Alabama'
  },
  {
    value: 'new-york',
    label: 'New York'
  },
  {
    value: 'san-francisco',
    label: 'San Francisco'
  }
];

// eslint-disable-next-line react/prop-types
const FormDescripcion = ({ data, regresar }) => {
  const [zxxx, setValues] = useState("")
  const { http } = backend
  //   firstName: 'Katarina',
  //   lastName: 'Smith',
  //   email: 'demo@devias.io',
  //   phone: '',
  //   state: 'Alabama',
  //   country: 'USA'
  // });

  const modificar = (values) => {
    http.post(`api/actualizar_descripcion_minima`, values).then((resp) => {
      const r = resp.data;
      regresar()
    }).catch((error) => {
      console.log(error)
    }).finally(() => {
    });
  };
  return (
    <Formik
      initialValues={{ ...data }}
      validationSchema={Yup.object().shape({
        item: Yup.string().max(255).required('El usuario es requerido'),
        hsc: Yup.string().max(255).required('Password es requerido')
      })}
      onSubmit={(values) => {
        modificar(values)
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader subheader="Puede editar la información" title="Editar Mercadería" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.item && errors.item)}
                    fullWidth
                    helperText={touched.item && errors.item}
                    label="Item"
                    margin="normal"
                    name="item"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.item}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.hsc && errors.hsc)}
                    fullWidth
                    helperText={touched.hsc && errors.hsc}
                    label="H.S.C"
                    margin="normal"
                    name="hsc"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.hsc}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.descripcion && errors.descripcion)}
                    fullWidth
                    helperText={touched.descripcion && errors.descripcion}
                    label="Descripción"
                    margin="normal"
                    name="descripcion"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.descripcion}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.marca && errors.marca)}
                    fullWidth
                    helperText={touched.marca && errors.marca}
                    label="Marca"
                    margin="normal"
                    name="marca"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.marca}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.clase && errors.clase)}
                    fullWidth
                    helperText={touched.clase && errors.clase}
                    label="Clase"
                    margin="normal"
                    name="clase"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.clase}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.modelo && errors.modelo)}
                    fullWidth
                    helperText={touched.modelo && errors.modelo}
                    label="Modelo"
                    margin="normal"
                    name="modelo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.modelo}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.cuantitativo1 && errors.cuantitativo1)}
                    fullWidth
                    helperText={touched.cuantitativo1 && errors.cuantitativo1}
                    label="Cuantitativo 1"
                    margin="normal"
                    name="cuantitativo1"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.cuantitativo1}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.cuantitativo2 && errors.cuantitativo2)}
                    fullWidth
                    helperText={touched.cuantitativo2 && errors.cuantitativo2}
                    label="Cuantitativo 2"
                    margin="normal"
                    name="cuantitativo2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.cuantitativo2}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.forma_presentacion && errors.forma_presentacion)}
                    fullWidth
                    helperText={touched.forma_presentacion && errors.forma_presentacion}
                    label="Forma de Presentación"
                    margin="normal"
                    name="forma_presentacion"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.forma_presentacion}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.naturaleza && errors.naturaleza)}
                    fullWidth
                    helperText={touched.naturaleza && errors.naturaleza}
                    label="Naturaleza"
                    margin="normal"
                    name="naturaleza"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.naturaleza}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.uso_aplicacion && errors.uso_aplicacion)}
                    fullWidth
                    helperText={touched.uso_aplicacion && errors.uso_aplicacion}
                    label="Uso o Aplicación"
                    margin="normal"
                    name="uso_aplicacion"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.uso_aplicacion}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.otras_caracteristicas && errors.otras_caracteristicas)}
                    fullWidth
                    helperText={touched.otras_caracteristicas && errors.otras_caracteristicas}
                    label="Otras Características"
                    margin="normal"
                    name="otras_caracteristicas"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.otras_caracteristicas}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.pais_origen && errors.pais_origen)}
                    fullWidth
                    helperText={touched.pais_origen && errors.pais_origen}
                    label="Pais de Orígen"
                    margin="normal"
                    name="pais_origen"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.pais_origen}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
              <Button color="secondary" variant="contained" onClick={() => regresar()}>
                Regresar
              </Button>
              <Button color="primary" variant="contained" type="submit">
                Modificar
              </Button>
            </Box>
          </Card>
          {/*{ errorMessage ? <Alert severity="error">{message}</Alert> : ""}*/}
        </form>
      )}
    </Formik>
  );
};

export default FormDescripcion;
