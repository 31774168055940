import { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader, Container,
  Divider,
  Grid, MenuItem,
  TextField, Typography
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import backend from '../../api/backend';
import al from '../../mixins/alertas'

const AccountProfileDetails = ({ data }) => {
  const { http } = backend
  // const [values, setValues] = useState({
  //   firstName: 'Katarina',
  //   lastName: 'Smith',
  //   email: 'demo@devias.io',
  //   phone: '',
  //   state: 'Alabama',
  //   country: 'USA'
  // });
  const modificarUser = async (values) => {
    al.openLoading("Actualizando")
    http.post(`api/update_user/${values.id}`, values).then((resp) => {
      const r = resp.data;
      al.closeLoading()
      if (r.status === 'success') {
        al.openSuccess("Resistro Exitoso, debe salir y volver a ingresar para ver los cambios", 3000)
      } else {
        al.openError(r.message)
      }
    }).catch((error) => {
      console.log(error)
    }).finally(() => {
    });
  }
  return (
    <Card>
      <CardHeader subheader="Actualizar información del usuario" title="Perfil de usuario" />
      <Divider />
      <CardContent>
        <Container maxWidth="md">
          <Formik
            initialValues={{
              id: data.id,
              email: data.email,
              fullname: data.fullname,
              correo: data.correo,
              password: '',
              nit: data.nit,
              company_name: data.company_name,
              cellphone: data.cellphone,
              foto: data.foto,
              tipoUser: data.type_user_id,
              estado: data.estado,
            }}
            validationSchema={
              Yup.object().shape({
                correo: Yup.string().email('Debe ser un correo válido').max(255).required('Correo es requerido'),
                fullname: Yup.string().max(255).required('El nombre es requerido'),
                email: Yup.string().max(255).required('El usuario es requerido'),
                password: Yup.string().max(255).required('Password es requerido'),
              })
            }
            onSubmit={(values) => {
              modificarUser(values)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={Boolean(touched.fullname && errors.fullname)}
                      fullWidth
                      helperText={touched.fullname && errors.fullname}
                      label="Nombre completo"
                      margin="normal"
                      name="fullname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.fullname}
                      variant="outlined"
                      size="small"
                    />
                    <TextField
                      error={Boolean(touched.correo && errors.correo)}
                      fullWidth
                      helperText={touched.correo && errors.correo}
                      label="Correo electrónico"
                      margin="normal"
                      name="correo"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.correo}
                      variant="outlined"
                      size="small"
                    />
                    <TextField
                      fullWidth
                      label="Nit"
                      margin="normal"
                      name="nit"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.nit}
                      variant="outlined"
                      size="small"
                    />
                    <TextField
                      fullWidth
                      label="Celular"
                      margin="normal"
                      name="cellphone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values.cellphone}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Usuario"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      variant="outlined"
                      size="small"
                    />
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Contraseña"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                      size="small"
                    />
                    <TextField
                      fullWidth
                      label="Nombre empresa"
                      margin="normal"
                      name="company_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.company_name}
                      variant="outlined"
                      size="small"
                    />
                    <TextField
                      fullWidth
                      label="Estado"
                      margin="normal"
                      name="estado"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      select
                      value={values.estado}
                      variant="outlined"
                      size="small"
                    >
                      <MenuItem value="ACT">ACTIVO</MenuItem>
                      <MenuItem value="INA">INACTIVO</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                      <Box sx={{ py: 2 }}>
                        <Button
                          color="primary"
                          fullWidth
                          size="small"
                          type="submit"
                          variant="contained"
                        >
                          Modificar
                        </Button>
                      </Box>
                    </Grid>
                    {/*{ errorMessage ? <Alert severity="error">{message}</Alert> : ""}*/}
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Container>
      </CardContent>
      <Divider />
      {/*<Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>*/}
      {/*  <Button color="primary" variant="contained">*/}
      {/*    Save details*/}
      {/*  </Button>*/}
      {/*</Box>*/}
    </Card>
  );
};

export default AccountProfileDetails;
